import MainHeader from '@/components/MainHeader'
import BreadcrumbNav from '@/components/layouts/main-layout/BreadcrumbNav'

import MainLayout, { menuToggle } from '@/components/layouts/main-layout/MainLayout'
import NavMenu from '@/components/orchestrators/nav-menu/NavMenu'
import { UserContext } from '@/components/providers/UserProvider'
import { Separator } from '@/components/ui/separator'
import { fetchPersonification, getCurrentProfile, getCurrentUser, getMainUser, setCurrentUser, storesRemapper } from '@/services/api/profiles.service'
import React, { useContext, useEffect } from 'react'
import { globalRouter } from './_app'

const mainHeaderConfig = [
    { name: 'home', label: 'Home', href: '/' },
    { name: 'about', label: 'About', href: '/about' },
    { name: 'contact', label: 'Contact', href: '/contact' },
]

let personificationData: any = null;

export const setPersonification = (user: any) => { // user: { name: string, role: string }
    if (personificationData) {
        personificationData.setPersonification({ active: true, userInfo: user });
    }
}

export let ejectPersonification = async () => {
    if (personificationData) {
        const userInfo = getCurrentUser();
        const personificationResponse = await fetchPersonification(0);
        userInfo.permissionList = personificationResponse?.data?.response?.features ?? [];
        userInfo.stores = storesRemapper(personificationResponse?.data?.stores ?? {});
        userInfo.personificated = false;
        userInfo.addressees = null;
        userInfo.currentDestination = null;
        userInfo.customer = null;
        userInfo.customers = userInfo.customers.map((customer: any) => {
            customer.personificated = false;
            return customer;
        });
        setCurrentUser(userInfo);
        personificationData.setPersonification({ active: false, userInfo: userInfo });
        ((personificationData?.personification?.userInfo?.role === 'ADMIN') || personificationData?.personification?.userInfo?.role === 'SUPERVISOR') ? globalRouter.push('/backoffice/') : globalRouter.push('/catalogue/');
    }
}

export default function Layout({ children }: { children: React.ReactNode }) {
    const { user, setUser } = useContext(UserContext ?? {});

    personificationData = { personification: user, setPersonification: setUser };

    useEffect(() => {
        const currentUser = getCurrentUser();
        setUser({ active: currentUser?.personificated, userInfo: currentUser });
    }, []);

    return (
        <>
            <MainHeader links={mainHeaderConfig} onMenuButtonClick={menuToggle}></MainHeader>
            <MainLayout>
                <div className='flex flex-col w-full h-full'>
                    <div>
                        <BreadcrumbNav />
                        <NavMenu />
                    </div>
                    <Separator className=''></Separator>
                    <div className='flex flex-1 overflow-hidden'>
                        {children}
                    </div>
                </div>
            </MainLayout>
        </>
    )
}
