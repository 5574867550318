'use client'

import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { GenericObject } from "@/models/UtilsModel";
import { ColumnDef } from "@tanstack/react-table";
import { CircleCheckIcon, LoaderCircleIcon, ShoppingCartIcon } from "lucide-react";
import { DataTableRowActions } from "./data-table-row-actions";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import ProductCard from "./product-card";

let tableColumnsDictionary: GenericObject | null = null;

export function DataProductCards({ ...props }) {
    const data = props?.table?.getRowModel() ? props?.table?.getRowModel()?.rows : [];
    const cardColumns = getCardColumns(props.cardCfg, props.tableDictionary?.columns);
    const checkboxTemplate: any = (cardColumns[0]?.id === 'select') ? cardColumns[0] : { cell: () => null };
    const actionsColumn = cardColumns.filter((columnItem: any) => columnItem.type === 'action');

    return (
        <div className="w-full h-full flex flex-col">

            {
                (props.enableMassActions && (checkboxTemplate)) &&
                <div className="px-4 py-2 flex border">
                    <div className="flex flex-row items-center">
                        <Checkbox
                            id="massive-selection"
                            checked={
                                props.table?.getIsAllPageRowsSelected() ||
                                (props.table?.getIsSomePageRowsSelected() && "indeterminate")
                            }
                            onCheckedChange={(value: any) => props.table?.toggleAllPageRowsSelected(!!value)}
                            aria-label="Select all"
                            className="data-[state=checked]:bg-primary"
                        />
                        <label htmlFor="massive-selection" className="ml-2 cursor-pointer">Select all checkbox</label>
                    </div>
                </div>
            }
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 min-[1920px]:grid-cols-5 [@media(min-width:1920px)]:grid-cols-5 gap-4 overflow-auto p-4 w-full">
                {
                    data.map((item: any, index: number) => (
                        <ProductCard
                            key={item.original.id + '-' + index}
                            row={item}
                            actionColumn={actionsColumn}
                            activeRow={item?.original?.id === props.itemSelection?.original?.id ? 'active-row' : ''}
                            itemSelection={props.itemSelection}
                            itemSelectionFn={props.itemSelectionFn}
                            dictionary={props.tableDictionary}
                            cardCfg={props.cardCfg}
                        >
                        </ProductCard>
                    ))
                }
            </div>
        </div>
    );
};

let throttleTimeout: any = null;

const changeChartValue = (item: any, storage: any, value: any, cb: any) => {
    clearTimeout(throttleTimeout);
    throttleTimeout = setTimeout(() => {
        console.log(storage, value);
        item.original.storage = item?.original?.storage.map((str: any) => {
            if (str.type === storage.type) {
                str.value = value;
            }
            return str;
        });
        console.log(item?.original);
        cb(item?.original);
    }, 50);
}

const getChartPopoverTemplate = (storageItem: any) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button className="size-5" variant="outline" size="icon">
                    <ShoppingCartIcon className="size-3" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
                <div className="grid gap-4">
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none">Dimensions</h4>
                        <p className="text-sm text-muted-foreground">
                            Set the dimensions for the layer.
                        </p>
                    </div>
                    <div className="grid gap-2">
                        <div className="w-full items-center flex flex-row items-center justify-between">
                            <Label htmlFor="width">Width</Label>
                            <Input
                                type="number"
                                min={0}
                                id="width"
                                defaultValue="0"
                                className="h-6 max-w-[12rem] text-right pr-3"
                                width={'1rem'}
                            />
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
};

const getCardColumns = (columnsCfg: any, dictionary: GenericObject | null = null) => {
    tableColumnsDictionary = dictionary;
    let columns: ColumnDef<any>[] = [];
    if (columnsCfg.checkboxRow && (columnsCfg?.massiveActions?.length > 0)) {
        const columnTemplate = getColumnTemplate({ type: 'checkboxRow' });
        columnTemplate ? columns.push(columnTemplate) : null
    }

    if (columnsCfg.columns) {
        columnsCfg.columns.forEach((column: GenericObject) => {
            const columnTemplate = getColumnTemplate(column);
            columnTemplate ? columns.push(columnTemplate) : null;
        })
    }

    return columns;
}

const getColumnTemplate: (columnItem: any) => ColumnDef<any> | null = (columnItem: any) => {
    switch (columnItem.type) {
        case 'checkboxRow':
            return getCheckboxRowTemplate(columnItem);
        case 'base':
            return getBaseTemplate(columnItem);
        case 'filter':
            return getFilterTemplate(columnItem);
        case 'action':
            return getActionTemplate(columnItem);
        default:
            return null;
    }
}

const getCheckboxRowTemplate = (columnItem: any) => {
    return {
        id: "select",
        cell: ({ row }: any) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value: any) => { row.toggleSelected(!!value) }}
                aria-label="Select row"
                className="data-[state=checked]:bg-primary border-primary"
                onClick={(e: any) => { e.stopPropagation() }}
            />
        ),
        enableSorting: false,
        enableHiding: false,
    }
}

const getBaseTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            const label = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);

            return (
                <div className="flex items-center space-x-2">
                    {
                        (columnItem.icons) &&
                        columnItem.icons.map((iconItem: any, index: number) => {
                            return (
                                iconItem?.onClick ?
                                    <Tooltip key={iconItem.name + '-' + index} delayDuration={0} disableHoverableContent={false}>
                                        <TooltipTrigger>
                                            <Badge
                                                variant="outline"
                                                key={iconItem.name + '-' + index}
                                                className={"cursor-pointer block"}
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    await iconItem.onClick(row?.original, iconItem.name);
                                                }}
                                            >
                                                {
                                                    ((row?.original?.status?.state === 'pending') && (iconItem.name === row?.original?.status?.name)) &&
                                                    <LoaderCircleIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                }
                                                {
                                                    ((row?.original?.status?.state === 'success') && (iconItem.name === row?.original?.status?.name)) &&
                                                    <CircleCheckIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                }
                                                {
                                                    ((!row?.original?.status?.state) || !(iconItem.name === row?.original?.status?.name)) &&
                                                    <iconItem.icon name={iconItem.name} size={iconItem.size ?? 18} className={iconItem.className + ' ' + ((row?.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                                                }
                                            </Badge>
                                        </TooltipTrigger>
                                        <TooltipContent side="bottom" className={"flex items-center gap-4" + " " + (tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip) ? '' : 'display-none'}>
                                            {
                                                (() => {
                                                    const tooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip ?? '';
                                                    const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.disabledTooltip ?? '';
                                                    return !(row?.original[iconItem.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                })()
                                            }
                                        </TooltipContent>
                                    </Tooltip>
                                    :
                                    <iconItem.icon key={iconItem.name + '-' + index} name={iconItem.name} size={iconItem.size ?? 18} className={'mr-1 ' + iconItem.className + ' ' + ((row?.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                            )
                        })
                    }
                    <span className="max-w-full truncate font-medium bold">
                        {label}
                    </span>
                </div>
            )
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false,
        filterFn: (row: any, id: any, value: any) => {
            const rowValue = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);
            console.log(rowValue.toLowerCase(), (value.toLowerCase()));
            return rowValue.toLowerCase().includes(value.toLowerCase());
        },
    }
}

const getFilterTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            const option: GenericObject = columnItem.options.find(
                (option: GenericObject) => option.name === row.getValue(columnItem.name)
            )

            if (!option) {
                return null
            }

            return (
                <div className="flex items-center">
                    {option.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{tableColumnsDictionary?.[columnItem.name]?.options?.[option.name]?.label ?? option.label}</span>
                </div>
            )
        },
        filterFn: (row: any, id: any, value: any) => {
            return value.includes(row.getValue(id))
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false
    }
}

const getActionTemplate = (columnItem: any) => {
    return {
        id: columnItem.name,
        type: 'action',
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            return (
                <div className="flex items-center space-x-2">
                    {
                        columnItem.actions.filter((action: any) => !action.subMenu).map((action: any, index: number) => {
                            return (
                                <Tooltip key={action.name + '-' + index} delayDuration={0}>
                                    <TooltipTrigger>
                                        <label
                                            className={"cursor-pointer block"}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await action.onClick(row?.original, action.name);
                                            }}
                                        >
                                            {
                                                ((row?.original?.status?.state === 'pending') && (action.name === row?.original?.status?.name)) &&
                                                <LoaderCircleIcon name={action.name} size={action.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                            }
                                            {
                                                ((row?.original?.status?.state === 'success') && (action.name === row?.original?.status?.name)) &&
                                                <CircleCheckIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                            }
                                            {
                                                (!(row?.original?.status?.state) || !(action.name === row?.original?.status?.name)) &&
                                                <action.icon name={action.name} size={action.size ?? 18} className={action?.className + ' ' + ((row?.original?.[action?.classNameConditionKey]) && action?.classNameActive)} />
                                            }
                                        </label>
                                    </TooltipTrigger>
                                    <TooltipContent side="bottom" className="flex items-center gap-4">
                                        {
                                            (() => {
                                                const tooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.tooltip ?? '';
                                                const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.disabledTooltip ?? '';
                                                return !(row?.original?.[action.classNameConditionKey]) ? tooltip : disabledTooltip;
                                            })()
                                        }
                                    </TooltipContent>
                                </Tooltip>
                            )
                        })
                    }
                    {
                        (() => {
                            const filteredActions = columnItem.actions.filter((action: any) => action.subMenu);
                            return filteredActions.length ? <DataTableRowActions row={row} actions={filteredActions} /> : null;
                        })()
                    }
                </div>
            )
        }
    }
}
