'use client'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ArrowBigDownDashIcon, ArrowBigUpDashIcon, BarChartIcon, BlocksIcon, CalendarDays, EyeIcon, MessageSquareWarningIcon, PuzzleIcon, ShoppingCart } from "lucide-react";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { useRouter } from "next/router";
import ProductHoverCardTemplate from "./product-hover-card-template";
import ProductHoverCardListTemplate from "./product-hover-card-list-template";

const getWarehouseInfos = (str: any, mode: 'normal' | 'extended' = 'normal') => {
    return <div>
        {
            ((str.type == 'WAREHOUSE') && (str.status == 'AVAILABLE')) ? <label className="">{(mode == 'normal') ? '' : 'Disponibile \xa0'}</label> : ''
        }
        {((str.type == 'WAREHOUSE') && (str.status == 'INCOMING')) ? <label className="">In arrivo al magazzino</label> : ''}
        {((str.type == 'WAREHOUSE') && (str.status == 'UNAVAILABLE')) ? <label className="truncate">Non disponibile magazzino</label> : ''}

        {((str.type == 'NETWORK') && (str.status == 'AVAILABLE')) ? <label className="">Disponibile rete vendita</label> : ''}
        {((str.type == 'NETWORK') && (str.status == 'INCOMING')) ? <label className="">In arrivo alla rete vendita</label> : ''}
        {((str.type == 'NETWORK') && (str.status == 'UNAVAILABLE')) ? <label className="">Non disponibile alla rete vendita</label> : ''}

        {((str.type == 'TRANSFER') && (str.status == 'AVAILABLE')) ? <label className="">Disponibile transfer order</label> : ''}
        {((str.type == 'TRANSFER') && (str.status == 'INCOMING')) ? <label className="">In arrivo transfer order</label> : ''}
        {((str.type == 'TRANSFER') && (str.status == 'UNAVAILABLE')) ? <label className="">Non disponibile transfer order</label> : ''}

        {((str.type == 'PRIORITY') && (str.status == 'AVAILABLE')) ? <label className="">Disponibile ordine prioritario</label> : ''}
        {((str.type == 'PRIORITY') && (str.status == 'INCOMING')) ? <label className="">In arrivo ordine prioritario</label> : ''}
        {((str.type == 'PRIORITY') && (str.status == 'UNAVAILABLE')) ? <label className="">Non disponibile ordine prioritario</label> : ''}
    </div>
};

let throttleTimeout: any = null;

const changeChartValue = (item: any, storage: any, value: any, cb: any) => {
    clearTimeout(throttleTimeout);
    throttleTimeout = setTimeout(() => {
        console.log(storage, value);
        if (item?.original) {
            item.original.storage = item?.original?.storage.map((str: any) => {
                if (str.type === storage.type) {
                    str.value = value;
                }
                return str;
            });
            console.log(item?.original);
            cb(item?.original);
        }
    }, 50);
}

const ProductCard = ({ ...props }) => {
    const router = useRouter();
    const { locale } = router;

    const actionsColumn = props?.actionColumn ?? null;
    const row = props.row;
    const data = row?.original;
    const activeRow = props.activeRow ?? '';
    const itemSelectionFn = props.itemSelectionFn ?? (() => { });

    return (
        <Card
            className={cn(
                "shadow-lg" + " " + activeRow,
                "flex-1"
            )}
        /* onClick={() => itemSelectionFn(props.row)} */
        >
            <CardHeader className="px-3 py-2 pt-3 border-b">
                <CardTitle className="text-base w-full">
                    <div className="flex flex-1 overflow-hidden">
                        <span className="text-xs text-muted-foreground truncate">
                            {props.dictionary?.title?.options?.[props.row.original.title]?.label ?? data?.title}
                        </span>
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <div className="flex flex-row flex-1 items-center truncate">
                            {/* {
                                            (checkboxTemplate && checkboxTemplate.cell) &&
                                            checkboxTemplate.cell({ row: props.row })
                                        } */}
                            <label
                                className="text-l overflow-hidden truncate"
                            >
                                {props.dictionary?.name?.options?.[props.row.original.name]?.label ?? data?.name}

                            </label>
                        </div>
                    </div>
                    <div className="flex flex-1 overflow-hidden">
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                                <span className="text-xs text-muted-foreground truncate cursor-pointer">
                                    {props.dictionary?.description?.options?.[props.row.original.description]?.label ?? data?.description}
                                </span>
                            </TooltipTrigger>
                            <TooltipContent side="bottom" className="flex items-center gap-4 max-w-[20rem]">
                                <label className="">
                                    {props.dictionary?.description?.options?.[props.row.original.description]?.label ?? data?.description}
                                </label>
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent className="p-0 cursor-default" onClick={(ev) => { ev.stopPropagation() }}>
                <div className="pl-2 h-[8rem] !h-auto flex flex-row flex-1">
                    <div
                        className="w-[110px] h-[110px] py-[0.5rem] cursor-pointer"
                        onClick={async (e) => {
                            e.stopPropagation();
                            itemSelectionFn(row);
                        }}>
                        {
                            data?.imageLink ?
                                <img className="h-full w-full object-contain" src={(data?.imageLink) ? data.imageLink : ''} alt="" />
                                :
                                <svg
                                    version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg"
                                    className="w-full h-full object-contain fill-primary dark:stroke-primary-foreground"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 500 500"
                                >
                                    <g>
                                        <path d="M250,48C138.44,48,48,138.44,48,250s90.44,202,202,202s202-90.44,202-202S361.56,48,250,48z M119.29,264.26
                                        c0-36.75,29.79-66.54,66.54-66.54s66.54,29.79,66.54,66.54c0,36.75-29.79,66.54-66.54,66.54S119.29,301.01,119.29,264.26z
                                        M250,402.04c-42.27,0-80.5-17.26-108.05-45.1h216.11C330.5,384.78,292.27,402.04,250,402.04z M297.53,219.11v-23.76h43.49v23.76
                                        H297.53z M371.83,340.97V176.33h-31.38v-18.11h-47.07v18.11H117c25.94-46.73,75.77-78.37,133-78.37
                                        c83.97,0,152.04,68.07,152.04,152.04C402.04,284.11,390.81,315.61,371.83,340.97z"/>
                                        <circle cx="185.84" cy="264.26" r="38.02" />
                                    </g>
                                </svg>
                        }
                    </div>
                    <div className="h-100 pl-[1.2rem] flex flex-col flex-1 overflow-hidden ">
                        <div className="flex flex-col flex-1 overflow-auto py-[0.3rem]">
                            {/* <div className={"w-full flex flex-col mb-[0.1rem]" + " " + ((!data?.kitBundleId) ? 'invisible' : '')}>
                                            <span className="text-[0.6rem] font-semibold">
                                                {(data?.kitBundleId) ? ((data?.kitBundleId == data?.id) ? 'Parte di un Bundle' : 'Presente nel Kit') : 'Standalone'}
                                            </span>
                                        </div> */}
                            {
                                data?.prices?.map((price: any, index: number) => {
                                    return (
                                        <div key={price.name + '-' + index} className="w-full flex flex-col">
                                            <span className="text-[0.625rem]">
                                                {price.name == 'PUBLIC' && 'PREZZO AL PUBBLICO'}
                                                {price.name == 'PUBLIC_NO_VAT' && 'PREZZO IVA ESCLUSA'}
                                                {price.name == 'PRIVATE' && 'PREZZO ALL\'INGROSSO'}
                                            </span>
                                            <div className="text-lg font-semibold flex flex-row items-center">
                                                <label className="" htmlFor="">
                                                    {new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(price.value)}
                                                </label>
                                                <HoverCard>
                                                    <HoverCardTrigger asChild>
                                                        <span className="ml-1 cursor-pointer">
                                                            {(price?.fluctuation && (price?.fluctuation > 0)) ? <ArrowBigUpDashIcon className="w-6 h-6 stroke-red-400 hover:stroke-secondary-foreground" /> : ''}
                                                            {(price?.fluctuation && (price?.fluctuation) < 0) ? <ArrowBigDownDashIcon className="w-6 h-6 stroke-green-400 hover:stroke-secondary-foreground" /> : ''}
                                                        </span>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="w-90 border-2">
                                                        <div className="flex justify-between space-x-4 items-center">
                                                            <div>
                                                                {/* <AvatarImage src="https://soundcloudreviews.org/wp-content/uploads/2017/03/price-up-or-down.png" /> */}
                                                                {(price.fluctuation && (price.fluctuation > 0)) ? <ArrowBigUpDashIcon className="w-[3rem] h-[3rem] stroke-red-400" /> : ''}
                                                                {(price.fluctuation && (price.fluctuation < 0)) ? <ArrowBigDownDashIcon className="w-[3rem] h-[3rem] stroke-green-400" /> : ''}
                                                            </div>
                                                            <div className="space-y-1">
                                                                <h4 className="text-sm font-semibold">{"Oscillazione dall'ultimo prezzo"}</h4>
                                                                <div className="text-sm font-normal">
                                                                    <label className="flex flex-1 flex-row justify-between" htmlFor="">
                                                                        <span>Precendente:</span>
                                                                        <span>{new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format((price.value - price.fluctuation))}</span>
                                                                    </label>
                                                                    <label className="flex flex-1 flex-row justify-between" htmlFor="">
                                                                        <span>Attuale:</span>
                                                                        <span>{new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format((price.value))}</span>
                                                                    </label>
                                                                    <label className="flex flex-1 flex-row justify-between" htmlFor="">
                                                                        <span>Variazione:</span>
                                                                        <span>{new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(price.fluctuation)}</span>
                                                                    </label>
                                                                </div>
                                                                <div className="flex items-center pt-2">
                                                                    <CalendarDays className="mr-1 mb-1 h-4 w-4 opacity-70" />{" "}
                                                                    <span className="text-xs text-muted-foreground">
                                                                        Ultima oscillazione: {new Date(price.lastUpdated ?? '').toLocaleDateString(locale).replaceAll('/', '-')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </HoverCardContent>
                                                </HoverCard>
                                            </div>
                                        </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* <Separator></Separator>
                            {
                                templateList?.map((columnTemplate: any, index: number) => {
                                    return (
                                        <div
                                            key={'card-row-' + index}
                                            className="flex flex-row items-center py-2 space-x-2 justify-between"
                                        >
                                            {columnTemplate.header({ column: columnTemplate })}
                                            {columnTemplate.cell({ row: props.row })}
                                        </div>
                                    );
                                })
                            } */}
                <Separator></Separator>
                <div className="h-9 flex flex-row items-center justify-between px-3">
                    <div className="flex flex-row items-center">
                        {
                            data?.productState &&
                            <Tooltip delayDuration={0}>
                                <TooltipTrigger asChild>
                                    <label className="truncate hover:text-primary cursor-pointer">
                                        ({data?.productState})
                                    </label>
                                </TooltipTrigger>
                                <TooltipContent side="bottom" className="flex items-center gap-4">
                                    <label className="">
                                        Stato - ({data?.productState})
                                    </label>
                                </TooltipContent>
                            </Tooltip>
                        }
                        {
                            data?.exclusive &&
                            <Tooltip delayDuration={0}>
                                <TooltipTrigger asChild>
                                    <label className="ml-1 truncate hover:text-primary cursor-pointer">
                                        <img
                                            src="/images/exclusive.png"
                                            alt="Authentication"
                                            className="block h-6 w-6"
                                        />
                                    </label>
                                </TooltipTrigger>
                                <TooltipContent side="bottom" className="flex items-center gap-4">
                                    <label className="">
                                        Prodotto esclusivo
                                    </label>
                                </TooltipContent>
                            </Tooltip>
                        }
                        {
                            data?.replacedId &&
                            <HoverCard>
                                <HoverCardTrigger asChild>
                                    <span className="ml-1 cursor-pointer">
                                        <MessageSquareWarningIcon className="ml-2 h-5 w-5 hover:stroke-primary" />
                                    </span>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-75 border-2">
                                    <ProductHoverCardTemplate
                                        replacedId={data?.replacedId}
                                        current={data}
                                        tableColumnsDictionary={props.dictionary}
                                    ></ProductHoverCardTemplate>
                                </HoverCardContent>
                            </HoverCard>
                        }
                        {
                            (data?.articleType === 'KIT') &&
                            <HoverCard>
                                <HoverCardTrigger asChild>
                                    <span className="ml-1 cursor-pointer">
                                        <PuzzleIcon className="ml-2 h-5 w-5 hover:stroke-primary" />
                                    </span>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-75 border-2">
                                    <ProductHoverCardListTemplate
                                        title={'Prodotti presenti nel Kit'}
                                        productId={data?.id}
                                        fetchFn={data.fetchKitFn}
                                        current={data}
                                        tableColumnsDictionary={props.dictionary}
                                    ></ProductHoverCardListTemplate>
                                </HoverCardContent>
                            </HoverCard>
                        }
                        {
                            data?.bundleIds?.length &&
                            <HoverCard>
                                <HoverCardTrigger asChild>
                                    <span className="ml-1 cursor-pointer">
                                        <BlocksIcon className="ml-2 h-5 w-5 hover:stroke-primary" />
                                    </span>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-75 border-2">
                                    <ProductHoverCardListTemplate
                                        title={'Prodotti presenti nel Bundle'}
                                        ids={data?.bundleIds}
                                        current={data}
                                        tableColumnsDictionary={props.dictionary}
                                    ></ProductHoverCardListTemplate>
                                </HoverCardContent>
                            </HoverCard>
                        }
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                        {
                            actionsColumn?.map((columnTemplate: any, index: number) => {
                                return (
                                    <div
                                        key={'card-row-' + index}
                                        className="flex flex-row items-center py-2 space-x-2 justify-between hover:stroke-primary"
                                    >
                                        {columnTemplate.cell({ row: props.row })}
                                    </div>
                                );
                            })
                        }
                        <div className="flex items-center">
                            <Tooltip delayDuration={0}>
                                <TooltipTrigger>
                                    <label
                                        className={"cursor-pointer block"}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            itemSelectionFn(row);
                                        }}
                                    >
                                        <EyeIcon size={23} className={'hover:stroke-primary stroke-2'} />
                                    </label>
                                </TooltipTrigger>
                                <TooltipContent side="bottom" className="flex items-center gap-4">
                                    Vai al dettaglio
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <Separator></Separator>
                <div className="h-auto w-full overflow-hidden">
                    <div className="h-full w-full overflow-auto ">
                        <div className="p-2 pb-3 flex flex-col flex-1">
                            {
                                data?.storage?.map((str: any, index: number) => {
                                    return (
                                        str.viewCondition() ?
                                            <div
                                                key={str.type + '-' + index}
                                                /* className="text-[0.63rem] font-semibold flex flex-row flex-1 items-center justify-between mb-1 last:mb-0" */
                                                className={"grid gap-1 text-[0.700rem] font-semibold mb-1 last:mb-0 " +
                                                    ((str.type == 'WAREHOUSE') ? 'text-black dark:text-white ' : '') +
                                                    ((str.type == 'NETWORK') ? 'text-green-600 ' : '') +
                                                    ((str.type == 'TRANSFER') ? 'text-blue-600 ' : '') +
                                                    ((str.type == 'PRIORITY') ? 'text-purple-600 ' : '') +
                                                    ((str.status == 'UNAVAILABLE') ? (((str.quantity > 0) ? 'grid-cols-2 text-red-600 ' : ((str.type == 'WAREHOUSE') ? 'grid-cols-[76.75%_23.25%] text-red-600 ' : 'grid-cols-1 text-red-600 '))) : '') + /* ((str.type == 'WAREHOUSE') ? 'grid-cols-[66.66%_33.33%] ' : 'grid-cols-1 ') */
                                                    ((str.status == 'INCOMING') ? 'text-red-600 ' : '') +
                                                    ((str.status !== 'UNAVAILABLE') ? ((str.quantity > 0) ? 'grid-cols-[64%_36%] ' : 'grid-cols-[76.75%_23.25%] ') : 'grid-cols-1 ')
                                                }
                                            >
                                                <div className={'flex flex-row flex-1 items-center truncate ' + ((str.type !== 'AVAILABLE') ? '' : '')}>
                                                    {getWarehouseInfos(str, (str?.contraction ? 'normal' : 'extended'))}

                                                    {
                                                        (str.note && (str.status == 'AVAILABLE')) &&
                                                        <Tooltip delayDuration={0}>
                                                            <TooltipTrigger asChild>
                                                                <label className="truncate">
                                                                    {str.note}
                                                                </label>
                                                            </TooltipTrigger>
                                                            <TooltipContent side="right" className="flex items-center gap-4">
                                                                <label className="flex flex-row items-center">
                                                                    {getWarehouseInfos(str, (str?.contraction ? 'normal' : 'extended'))}
                                                                    &nbsp;
                                                                    {str.note}
                                                                </label>
                                                            </TooltipContent>
                                                        </Tooltip>

                                                    }
                                                    {(str.status == 'AVAILABLE') && ':'}
                                                </div>

                                                {
                                                    (str.status !== 'UNAVAILABLE' || str.type == 'WAREHOUSE') &&
                                                    <div
                                                        /* className="flex flex-row items-center" */
                                                        className={cn(
                                                            "grid grid-cols-1 ",
                                                            ((str.quantity > 0)) ? 'grid-cols-[35%_40%_25%] ' : 'grid-cols-[60%_40%] '
                                                            /* !!str.backOrder && "grid-cols-3" */
                                                        )}
                                                    >
                                                        {
                                                            ((str.quantity > 0)) &&
                                                            <span className="text-right justify-self-end">&nbsp; {str.quantity}</span>
                                                        }
                                                        <div className="flex flex-1 flex-row items-center pl-1">
                                                            {/* <ShoppingCartIcon className="h-3 w-3" /> */}
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                id="width"
                                                                className="h-4 text-right w-full border-2 rounded-md"
                                                                value={str.value ?? 0}
                                                                onChange={(e) => {
                                                                    const updatedProduct = changeChartValue(props.row, str, e.target.value, props.cardCfg?.onChartChange);
                                                                }}
                                                            />
                                                            {/* {getChartPopoverTemplate(str)} */}
                                                        </div>

                                                        {
                                                            <label htmlFor="" className="w-full flex flex-1 flex-center justify-left pl-1">
                                                                <ShoppingCart className="h-4 w-4" />
                                                            </label>
                                                        }

                                                        {/* <label
                                                            className={cn(
                                                                !!str.backOrder ? 'visible' : 'invisible'
                                                            )}
                                                        >
                                                            &nbsp; Bo: {str.backOrder}
                                                        </label> */}
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default ProductCard;
