'use client'

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { AccordionHeader } from '@radix-ui/react-accordion';
import { XIcon } from 'lucide-react';
import { setDataTable } from '../orchestrators/cool-table/components/data-table';

interface Filter {
    id: number;
    label: string;
}

const tempFilterCategories = [
    {
        category: "Colors",
        filters: [
            { id: 1, label: "Red" },
            { id: 2, label: "Blue" },
            { id: 3, label: "Green" },
        ],
    },
    {
        category: "Sizes",
        filters: [
            { id: 4, label: "Small" },
            { id: 5, label: "Medium" },
            { id: 6, label: "Large" },
        ],
    },
    {
        category: "Shapes",
        filters: [
            { id: 7, label: "Circle" },
            { id: 8, label: "Square" },
            { id: 9, label: "Triangle" },
        ],
    },
];

export let getSelectedF: any = () => { };
export let setSelectedF: any = () => { };

export let getAV: any = () => { };
export let setAV: any = () => { };

export let getBF: any = () => { };
export let setBF: any = () => { };

export let getSF: any = () => { };
export let setSF: any = () => { };

export let getGF: any = () => { };
export let setGF: any = () => { };

const FilterAccordion = ({ ...props }) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [accordionValue, setAccordionValue] = useState('');

    setSelectedF = (data: any) => setSelectedFilters(data);

    getAV = () => accordionValue;
    setAV = (data: any) => setAccordionValue(data);

    const [brandsFilter, setBrandsFilter] = useState<any[]>([]);
    const [specsFilter, setSpecsFilter] = useState<any[]>([]);
    const [gridsFilter, setGridsFilter] = useState<any[]>([]);

    getBF = () => brandsFilter;
    setBF = (data: any) => setBrandsFilter(data);

    getSF = () => specsFilter;
    setSF = (data: any) => setSpecsFilter(data);

    getGF = () => gridsFilter;
    setGF = (data: any) => setGridsFilter(data);


    const toggleFilter = async (filter: any, category: any) => {
        setSelectedFilters((prev: any) => {
            let result = null;
            if (prev.map((p: any) => p.id).includes(filter.id)) {
                result = prev.filter((f: any) => f.id !== filter.id);
            } else {
                filter.parentId = category?.id;
                filter.parentType = (category?.category === 'Brands') ? 'brands' : ((category?.category === 'Grids') ? 'grids' : 'specs');
                result = [...prev, filter]
            }
            const selectedBrands = result.filter((f: any) => f.parentType === 'brands').map((f: any) => f.id);
            const selectedSpecs: any = {};
            result.filter((f: any) => f.parentType === 'specs').forEach((f: any) => {
                selectedSpecs[f.parentId] = (selectedSpecs?.[f.parentId]) ? [...selectedSpecs[f.parentId], f.id] : [f.id];
            });
            const selectedGrids = result.filter((f: any) => f.parentType === 'grids').map((f: any) => f.id);
            setDataTable({
                brands: selectedBrands.length > 0 ? JSON.stringify(selectedBrands) : null,
                specs: JSON.stringify(selectedSpecs),
                grids: selectedGrids.length > 0 ? JSON.stringify(selectedGrids) : null,
            });
            return result;
        }
        );
    };

    const isSelected = (filterId: string, selectedFilters: any[]) => selectedFilters.map(sf => sf?.id).includes(filterId);

    return (brandsFilter || specsFilter || gridsFilter) ? (
        <Accordion type="single" collapsible className={"w-full flex flex-col flex-1 overflow-auto"} value={accordionValue} onValueChange={(val: any) => setAccordionValue(val)}>
            {(brandsFilter && (brandsFilter.length > 0)) ? accordionTemplate(brandsFilter, { type: 'brands' }) : null}
            {(gridsFilter && (gridsFilter.length > 0)) ? accordionTemplate(gridsFilter, { hideCounters: true }) : null}
            {(specsFilter && (specsFilter.length > 0)) ? accordionTemplate(specsFilter) : null}
            {
                (selectedFilters?.length > 0) &&
                <div
                    className={cn(
                        "border-b-0 h-full flex flex-col flex-1"
                    )}
                    onClick={async () => {
                        await setDataTable({
                            brands: null,
                            grids: null,
                            specs: null
                        });
                        setSelectedFilters([]);
                        setAccordionValue('');
                    }}
                >
                    <div
                        className={cn(
                            "AccordionHeader",
                            "bg-[#d9534f]/90 text-white hover:bg-[#d9534f]/100 pr-2 mt-2"
                        )}
                    >
                        <div
                            className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden"
                        >
                            <label
                                htmlFor=""
                                className={cn(
                                    "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                    "justify-start h-9 px-3 text-sm",
                                    "flex flex-row content-between w-full cursor-pointer truncate items-center justify-center",
                                    "label-background dark:label-white"
                                )}
                            >
                                Tutti i risultati
                            </label>
                        </div>
                    </div>
                </div>
            }
        </Accordion>
    ) : null;

    function accordionTemplate(filterList: any[], options?: any) {
        return <>
            {
                (filterList ?? []).map((category: any, index) => (
                    <AccordionItem
                        key={category.category + '-' + index}
                        value={category.category}
                        className={cn(
                            "group",
                            "border-b-0 h-full flex flex-col flex-1"
                        )}
                    >
                        <AccordionHeader
                            className={cn(
                                "AccordionHeader",
                                "bg-transparent border-secondary border-2 bg-background text-foreground hover:bg-secondary/90 hover:text-secondary-foreground pr-2 data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground",
                                (options?.type === 'brands') ? '' : 'mt-2'
                            )}
                            asChild
                        >
                            <AccordionTrigger
                                className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden"
                            >
                                <label
                                    htmlFor=""
                                    className={cn(
                                        "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                        "justify-start h-9 px-3 text-sm",
                                        "flex flex-row content-between w-full cursor-pointer truncate items-center",
                                        "label-background dark:label-white"
                                    )}
                                >
                                    {category.label}
                                </label>
                            </AccordionTrigger>
                        </AccordionHeader>
                        <div className={cn(
                            "selectedFilters flex flex-wrap gap-2 flex-row border-secondary px-0 group-data-[state=open]:border-0 group-data-[state=open]:p-0",
                            (category?.filters?.filter((f: any) => selectedFilters?.some((sf: any) => sf.id === f.id)).length > 0) ? 'border-[1px] border-t-0 !px-2' : ''
                        )}>
                            {
                                category?.filters?.filter((f: any) => selectedFilters?.some((sf: any) => sf.id === f.id)).map((c: any) => {
                                    return (
                                        <Button
                                            key={c.id}
                                            className={cn(
                                                "bg-secondary text-secondary-foreground hover:bg-secondary/70 hover:text-secondary-foreground",
                                                "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                                "justify-start h-auto px-2 py-2 rounded-none text-xs cursor-pointer",
                                                `${isSelected(c.id, selectedFilters) ? 'bg-secondary text-secondary-foreground' : ''}`
                                            )}
                                        >
                                            {c.label} {(c.count && (category?.filters?.filter((f: any) => selectedFilters.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0)) ? ('(' + c.count + ')') : ''}
                                            <XIcon
                                                className="w-4 h-4 ml-1"
                                                onClick={
                                                    () => toggleFilter(c, category)
                                                }
                                            ></XIcon>
                                        </Button>
                                    )
                                })
                            }
                        </div>
                        <AccordionContent
                            className="flex flex-col flex-1 !overflow-auto py-2 px-2 border-[1px] border-t-0 border-secondary"
                            asChild
                        >
                            <div className="flex flex-wrap gap-2 flex-row">
                                {category?.filters?.map((filter: any) => (
                                    <Button
                                        key={filter.id}
                                        className={cn(
                                            "bg-background text-foreground border-2 border-secondary hover:bg-secondary/70 hover:text-secondary-foreground",
                                            "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                            "justify-start h-auto px-2 py-2 rounded-none text-xs font-bold",
                                            `${isSelected(filter.id, selectedFilters) ? 'bg-secondary text-secondary-foreground' : ''}`
                                        )}
                                        onClick={() => toggleFilter(filter, category)}
                                    >
                                        {filter.label} {(filter.count && !options?.hideCounters && (category?.filters?.filter((f: any) => selectedFilters.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0) ? ('(' + filter.count + ')') : '')} {(filter.type ? ('(' + filter.type + ')') : '')}
                                    </Button>
                                ))}
                            </div>

                        </AccordionContent>
                    </AccordionItem>
                ))}
        </>
    }
};

export default FilterAccordion;
