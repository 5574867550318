'use client';


import { AppWindowIcon, CircleUser, ListTodoIcon, MailIcon, Refrigerator, ScanSearchIcon, SignpostIcon, SquareKanbanIcon, StampIcon, Star, StarIcon, TelescopeIcon, UserCogIcon, UserIcon } from "lucide-react";

import { getCookie, setCookie } from "cookies-next";
import axiosInterceptorInstance from "../axiosInterceptorInstance";
import { getDataApiState, getTableInstance, rerenderTable } from "@/components/orchestrators/cool-table/components/data-table";
import ProductImagesManager from "@/components/standalone/product-images-manager/ProductImagesManager";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Profile } from "@/models/ProfileModel";
import { setBF } from "@/components/presentational/FilterAccordion";
import { globalRouter } from "@/pages/_app";
import { ejectPersonification, setPersonification } from "@/pages/layout";
import { permission } from "process";

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getCurrentUser = () => {
    const storedUserInfo = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('CURRENT_USER') ?? '{}') : null;
    return storedUserInfo;
}

export const setCurrentUser = (userInfo: any) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('CURRENT_USER', JSON.stringify(userInfo));
    }
}

export async function getProfileList() {
    await timeout(3000);
    const newSnl = getProfiles();
    return newSnl;
}

export const getMainUser = async () => {
    try {
        const response = await getMainUserFetch(); // Replace with your API endpoint

        // Handle the response data here
        //console.log(response.data);
        /* response.data = {
            "id": "PROFILE-7839",
            "name": "Reina Shannon",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        } */

        /* setCookie('CURRENT_USER', JSON.stringify(response?.data?.response));
        if (typeof window !== 'undefined') {
            localStorage.setItem('CURRENT_USER', JSON.stringify(response?.data?.response));
        } */
        return {
            data: handleMainUser(response?.data?.response),
        };
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getMainUserFetch = async () => {
    const userInfoUrl: string = (
        (process.env.NEXT_PUBLIC_AUTH_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') +
        (process.env.NEXT_PUBLIC_AUTH_GET_USER_SESSION ?? '/b2b_auth/login')
    );
    const response = await axiosInterceptorInstance.get(userInfoUrl); // Replace with your API endpoint
    return response;
}

export const personificationByCustomerIdFetch = async (customerId: string) => {
    const userPersonificationInfos: string = (
        (process.env.NEXT_PUBLIC_AUTH_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') +
        (process.env.NEXT_PUBLIC_AUTH_GET_USER_SESSION ?? '/b2b_auth/customer/')
    );
    const response = await axiosInterceptorInstance.get(userPersonificationInfos); // Replace with your API endpoint
    return response;
};

const addresseesRemapper = (addressees: any) => {
    return Object.entries(addressees ?? {}).map((addressee: any) => {
        return {
            id: addressee[0],
            code: addressee[1]?.c_addressee,
            description: addressee[1]?.d_addressee,
            order: addressee[1]?.ordering
        }
    })
};

const customerRemapper = (customer: any) => {
    return {
        id: customer[0],
        name: customer[1]?.d_customer,
        code: customer[1]?.c_customer + ((customer[1]?.c_additional_customer_2) ? ' - ' + customer[1]?.c_additional_customer_2 : '') + (customer[1]?.c_additional_customer_3 ? ' - ' + customer[1]?.c_additional_customer_3 : ''),
        address: customer[1]?.address,
        city: customer[1]?.city,
        email: customer[1]?.email,
        currentDestination: addresseesRemapper(customer[1]?.addressees).filter(((addressee) => (addressee?.order == 0) || (addressee?.order == 1)))[0],
        destination: addresseesRemapper(customer[1]?.addressees).filter(((addressee) => (addressee?.order == 0) || (addressee?.order == 1)))?.[0]?.description,
        addressees: addresseesRemapper(customer[1]?.addressees),
        role: "USER",
        label: customer[1]?.d_customer,
        priority: 'high',
        preferred: false,
        personificated: false,
        status: null
    }
}

const customerRemapper2 = (customer: any, addressees: any[]) => {
    return [{
        id: customer.id_customer,
        name: customer?.d_customer,
        code: customer?.c_customer + ((customer?.c_additional_customer_2) ? ' - ' + customer?.c_additional_customer_2 : '') + (customer?.c_additional_customer_3 ? ' - ' + customer?.c_additional_customer_3 : ''),
        address: customer?.address,
        city: customer?.city,
        email: customer?.email,
        currentDestination: addresseesRemapper(addressees).filter(((addressee) => (addressee?.order == 0) || (addressee?.order == 1)))[0],
        destination: addresseesRemapper(addressees).filter(((addressee) => (addressee?.order == 0) || (addressee?.order == 1)))?.[0]?.description,
        addressees: addresseesRemapper(addressees),
        role: "USER",
        label: customer?.d_customer,
        priority: 'high',
        preferred: false,
        personificated: true,
        status: null
    }]
}

/* 0: non definito, 1: amministratore, 2: supervisore, 3: utente, 4: limitato */

const roleList: any[] = [null, 'ADMIN', 'SUPERVISOR', 'USER', 'LIMITED'];

export const handleMainUser = async (data: any) => {
    const refactoredData = {
        id: data?.id_user,
        name: data?.user_login,
        customer: data?.customer?.d_customer ?? '',
        addressees: addresseesRemapper(data?.addressees),
        currentDestination: addresseesRemapper(data?.addressees).filter(((addressee) => (addressee.order == 0) || (addressee.order == 1)))[0],
        email: data?.user_email,
        role: roleList[data?.id_group],
        label: data?.user_name,
        preferred: false,
        personificated: (roleList[data?.id_group] === 'USER') ? true : false,
        status: null,
        loggedIn: data?.logged_in,
        companyName: data?.company_name,
        /* stores: Object.entries(data?.stores ?? {}).map((store: any) => store[0]), */
        stores: storesRemapper(data?.stores),
        customers: (roleList[data?.id_group] == "ADMIN" || roleList[data?.id_group] == "SUPERVISOR") ? Object.entries(data?.agent_customers ?? {}).map((customer: any) => {
            return customerRemapper(customer);
        }) : (roleList[data?.id_group] == "USER" || roleList[data?.id_group] == "LIMITED") ? customerRemapper2(data?.customer, data?.addressees) : [],
        permissionList: data?.features ?? [],
    };
    setCookie('CURRENT_USER', JSON.stringify(refactoredData));
    if (typeof window !== 'undefined') {
        localStorage.setItem('CURRENT_USER', JSON.stringify(refactoredData));
    }
    setPersonification(refactoredData);
    return refactoredData;
}

export const onPreferenceChange = (rowOrigin: Profile, currentProfiles: Profile[]) => {
    const profiles = currentProfiles?.map((profile) => {
        if (profile.id === rowOrigin.id) {
            profile.preferred = !profile.preferred;
        }
        return profile;
    });
    if (typeof window !== 'undefined') {
        localStorage.setItem('PREFERRED_PROFILES', JSON.stringify(profiles.filter((profile) => profile.preferred)));
    }
    return profiles;
}

export const resetTablePersonification = (profileList: any) => {
    const profiles = profileList?.map((profile: any) => {
        profile.personificated = false;
        return profile;
    });

    if (getTableInstance()) {
        rerenderTable();
    }

    return profiles;
}

export const onPersonificationChange = (rowOrigin: Profile, currentProfiles: Profile[]) => {
    const profiles = currentProfiles?.map((profile) => {
        if (profile.id === rowOrigin.id) {
            profile.personificated = !profile.personificated;
        } else {
            profile.personificated = false;
        }
        return profile;
    });

    return profiles;
}

export const getPreferredProfiles = () => {
    const preferredProfiles = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('PREFERRED_PROFILES') ?? '[]') : null;
    return preferredProfiles;
}

export const getCustomerProfiles = () => {
    const userInfo = getCurrentUser();
    const customerProfiles = userInfo?.customers;
    return customerProfiles;
}

export const getDestinationList = () => {
    const userInfo = getCurrentUser();
    const destinations = userInfo?.addressees;
    return destinations;
}

export const setDestination = (destination: any) => {
    const userInfo = getCurrentUser();
    userInfo.currentDestination = destination;
    userInfo.destination = destination.description;
    userInfo.customers = userInfo.customers.map((customer: any) => {
        if (customer.personificated) {
            customer.currentDestination = destination;
            customer.destination = destination.description;
        }
        return customer;
    });

    if (typeof window !== 'undefined') {
        localStorage.setItem('CURRENT_USER', JSON.stringify(userInfo));
    }
    setPersonification(userInfo);
    const apiState: any = getDataApiState();
    apiState?.set(userInfo.customers);
    if (getTableInstance()) {
        rerenderTable();
    }
    return userInfo;
}

export const getCurrentProfile = () => {
    const currentProfile = (typeof window !== 'undefined') ? ((getCookie('CURRENT_USER')) ? JSON.parse(getCookie('CURRENT_USER') ?? '{}') : null) : null;

    const currentProfile2 = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('CURRENT_USER') ?? '{}') : null;

    return currentProfile ?? currentProfile2;
}

export async function fetchDataProfiles({ page, per_page }: { page: number, per_page: number }) {
    const userInfo = getCurrentUser();
    const profiles = userInfo?.customers;
    /* const profiles = getProfiles(); */
    const { data, total } = { data: profiles, total: profiles.length };
    return {
        data,
        pageCount: Math.ceil(total / per_page),
    };
}

export async function fetchDataProfilesDemo({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) {
    const profiles = getProfiles();
    const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({ ok: true, data: { data: profiles, total: profiles.length } });
        }, 1000)
    })

    const response = await promise.then((result: any) => result);
    /* const response = await axiosInterceptorInstance.get('/api/your-endpoint', {
        params: {
            page: pageIndex + 1,
            size: pageSize,
        },
    }); */
    const { data, total } = response.data;
    return {
        data,
        pageCount: Math.ceil(total / pageSize),
    };
}

export const fetchPersonification = async (customerId: number) => {
    const url: string = (process.env.NEXT_PUBLIC_API_BASE_URL + '/b2b_auth/customer');
    const response: any = await axiosInterceptorInstance.post(url, { id_customer: customerId });
    return response;
};

export function getProfiles() {
    const profiles = [
        {
            "id": "PROFILE-8782",
            "name": "Della Bean",
            "email": "example@test.com",
            "role": "user",
            "label": "Reina Shannon",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7878",
            "name": "Camilo O’Neill",
            "email": "test@test.com",
            "role": "affiliate",
            "label": "documentation",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7839",
            "name": "Reina Shannon",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5562",
            "name": "Zyair Maddox",
            "email": "example@test.com",
            "role": "user",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8686",
            "name": "Alaia Vo",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1280",
            "name": "Forrest Li",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7262",
            "name": "Heaven Bradley",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "feature",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1138",
            "name": "Cason Golden",
            "email": "example@test.com",
            "role": "admin",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7184",
            "name": "Aisha Elliott",
            "email": "example@test.com",
            "role": "operator",
            "label": "feature",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5160",
            "name": "Jaxton Vo",
            "email": "example@test.com",
            "role": "admin",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5618",
            "name": "Kallie Fry",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "documentation",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-6699",
            "name": "Marcellus Smith",
            "email": "example@test.com",
            "role": "manager",
            "label": "documentation",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-2858",
            "name": "Mara Marks",
            "email": "example@test.com",
            "role": "manager",
            "label": "user",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9864",
            "name": "Isaiah Perkins",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8404",
            "name": "Maren Suarez",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5365",
            "name": "Kash Parsons",
            "email": "example@test.com",
            "role": "admin",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1780",
            "name": "Haven Enriquez",
            "email": "example@test.com",
            "role": "operator",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-6938",
            "name": "Jay Rosas",
            "email": "example@test.com",
            "role": "operator",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9885",
            "name": "Lennox Nelson",
            "email": "example@test.com",
            "role": "manager",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-3216",
            "name": "Elijah Fisher",
            "email": "example@test.com",
            "role": "manager",
            "label": "documentation",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9285",
            "name": "Carl Galindo",
            "email": "example@test.com",
            "role": "operator",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1024",
            "name": "Roselyn Wagner",
            "email": "example@test.com",
            "role": "admin",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7068",
            "name": "Jason Barnett",
            "email": "example@test.com",
            "role": "viewer",
            "label": "user",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-6502",
            "name": "Amayah Fuentes",
            "email": "example@test.com",
            "role": "operator",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5326",
            "name": "Braxton Ross",
            "email": "example@test.com",
            "role": "operator",
            "label": "user",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-6274",
            "name": "Haven Buck",
            "email": "example@test.com",
            "role": "viewer",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1571",
            "name": "Leighton Haynes",
            "email": "example@test.com",
            "role": "admin",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9518",
            "name": "Aurelia Nava",
            "email": "example@test.com",
            "role": "viewer",
            "label": "documentation",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5581",
            "name": "Shepard Payne",
            "email": "example@test.com",
            "role": "manager",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-2197",
            "name": "Kyla Ingram",
            "email": "example@test.com",
            "role": "operator",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8484",
            "name": "Augustus Palacios",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9892",
            "name": "Iliana Cantu",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9616",
            "name": "Samson Stevens",
            "email": "example@test.com",
            "role": "admin",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-9744",
            "name": "Liberty Bean",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1376",
            "name": "Castiel Goodman",
            "email": "example@test.com",
            "role": "manager",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7382",
            "name": "Leanna Macias",
            "email": "example@test.com",
            "role": "operator",
            "label": "feature",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-2290",
            "name": "Joseph Blevins",
            "email": "example@test.com",
            "role": "viewer",
            "label": "documentation",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1533",
            "name": "Kyra Miranda",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-4920",
            "name": "Zaire Holmes",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5168",
            "name": "Abigail Small",
            "email": "example@test.com",
            "role": "admin",
            "label": "feature",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7103",
            "name": "Shawn Vu",
            "email": "example@test.com",
            "role": "viewer",
            "label": "feature",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-4314",
            "name": "Emily Mathis",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-3415",
            "name": "Korbin Anthony",
            "email": "example@test.com",
            "role": "operator",
            "label": "feature",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8339",
            "name": "Helen Noble",
            "email": "example@test.com",
            "role": "admin",
            "label": "feature",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-6995",
            "name": "Sonny Ellison",
            "email": "example@test.com",
            "role": "operator",
            "label": "feature",
            "priority": "high",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8053",
            "name": "Camilla Jacobs",
            "email": "example@test.com",
            "role": "operator",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-4336",
            "name": "Kyle Parra",
            "email": "example@test.com",
            "role": "operator",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8790",
            "name": "Emory Bailey",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "user",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-8980",
            "name": "Abraham Kim",
            "email": "example@test.com",
            "role": "viewer",
            "label": "user",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-7342",
            "name": "Stephanie Hoffman",
            "email": "example@test.com",
            "role": "manager",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-5608",
            "name": "Kye Cantrell",
            "email": "example@test.com",
            "role": "viewer",
            "label": "documentation",
            "priority": "low",
            "preferred": false,
            "personificated": false,
            "status": null
        },
        {
            "id": "PROFILE-1606",
            "name": "Brynn Daugherty",
            "email": "example@test.com",
            "role": "affiliate",
            "label": "feature",
            "priority": "medium",
            "preferred": false,
            "personificated": false,
            "status": null
        }
    ]

    const preferredProfiles = getPreferredProfiles();
    const preferredProfilesId = preferredProfiles ? preferredProfiles.map((profile: any) => profile.id) : [];

    const currentProfile = getCurrentProfile();

    let updatedProfiles = profiles.map((profile: Profile) => {
        if (preferredProfiles && preferredProfilesId.includes(profile.id)) {
            profile.preferred = true;
        };
        if (currentProfile.id === profile.id) {
            profile.personificated = currentProfile.personificated;
        }
        return profile;
    })

    return updatedProfiles;
}

const setRowStatus = (row: Profile, list: Profile[], triggerName: string, state: string) => {
    const results = list?.map((profile) => {
        if (profile.id === row.id) {
            profile.status = {
                name: triggerName,
                state: state
            };
        }
        return profile;
    })

    return results;
}

export const storesRemapper = (stores: any) => {
    if (!(stores)) return {};
    const storeList = Object.entries(stores ?? {}).map((store: any) => {
        return {
            id: store[0],
            name: store[1]?.d_store?.replace(' ', '_'),
            label: store[1]?.d_store
        }
    });

    const warehouseList: any[] = [];
    const networkList: any[] = [];

    storeList.forEach((store: any) => {
        switch (store.id) {
            case '1334':
                warehouseList.push(store);
                break;
            case '1986':
                warehouseList.push(store);
                break;
            case '189':
                warehouseList.push(store);
                break;
            case '185':
                networkList.push(store);
                break;
            default:
                break;
        }
    });

    return {
        warehouse: { list: warehouseList },
        network: { list: networkList }
    }
}

export const personificationChange = async (rowOrigin: any, triggerName: string = '') => {
    const profilesState: any = getDataApiState();
    const profiles = profilesState?.get || getCustomerProfiles();
    const setProfiles = profilesState?.set;

    let profileList: any[] = setRowStatus(rowOrigin, profiles, triggerName, 'pending');
    profileList = onPersonificationChange(rowOrigin, profileList);
    if (getTableInstance()) {
        rerenderTable();
    }

    const personification = profileList?.filter((profile) => profile.personificated)[0];
    let newPersonification: any = {};

    if (personification) {

        const personificationResponse = await fetchPersonification(Number(personification.id));
        const stores = personificationResponse?.data?.response?.stores;

        newPersonification = getCurrentUser();
        newPersonification.customer = profileList?.filter((profile) => profile.personificated)[0].name;
        newPersonification.customers = setRowStatus(rowOrigin, profiles, triggerName, '');
        newPersonification.currentDestination = profileList?.filter((profile) => profile.personificated)[0]?.currentDestination;
        newPersonification.addressees = profileList?.filter((profile) => profile.personificated)[0]?.addressees;
        newPersonification.personificated = profileList?.filter((profile) => profile.personificated)?.length ? true : false;
        newPersonification.permissionList = personificationResponse?.data?.response?.features ?? [];
        newPersonification.stores = storesRemapper(stores ?? null);

        if (newPersonification) {
            if (typeof window !== 'undefined') {
                localStorage.setItem('CURRENT_USER', JSON.stringify(newPersonification));
            }
            setCookie('CURRENT_USER', JSON.stringify(newPersonification));
        }
        setPersonification(newPersonification);
    } else {
        await ejectPersonification();
    }
    /* setProfiles(profileList); */
    profileList = setRowStatus(rowOrigin, profiles, triggerName, 'success');
    if (getTableInstance()) {
        rerenderTable();
    }
    await timeout(1000);
    profileList = setRowStatus(rowOrigin, profiles, triggerName, '');
    if (getTableInstance()) {
        rerenderTable();
    }
    return newPersonification;
}

export const preferenceChange = async (rowOrigin: any, triggerName: string = '') => {
    const profilesState: any = getDataApiState();
    const profiles = profilesState.get;
    const setProfiles = profilesState.set;

    let profileList = setRowStatus(rowOrigin, profiles, triggerName, 'pending');
    if (getTableInstance()) {
        rerenderTable();
    }
    await timeout(1000);
    profileList = onPreferenceChange(rowOrigin, profileList);

    /* setProfiles(profileList); */
    profileList = setRowStatus(rowOrigin, profiles, triggerName, 'success');
    if (getTableInstance()) {
        rerenderTable();
    }
    await timeout(1000);
    profileList = setRowStatus(rowOrigin, profiles, triggerName, '');
    if (getTableInstance()) {
        rerenderTable();
    }
};

export const massivePreferenceChange = (selectedRows: any, state: boolean) => {
    const profilesState: any = getDataApiState();
    const profiles = profilesState.get;
    const setProfiles = profilesState.set;
    const tableInstance: any = getTableInstance();

    let profileList = profiles;

    selectedRows.forEach((row: any) => {
        profileList = profileList.map((profile: any) => {
            if (profile?.id === row?.original?.id) {
                profile.preferred = state;
            }
            return profile;
        });
    });

    if (typeof window !== 'undefined') {
        localStorage.setItem('PREFERRED_PROFILES', JSON.stringify(profileList.filter((profile: any) => profile.preferred)));
    }

    if (getTableInstance()) {
        rerenderTable();
    }

    /* const currentPageIndex = tableInstance.getState().pagination.pageIndex;
    setProfiles(profileList); */
    /* setTimeout(() => {
        tableInstance.setPageIndex(currentPageIndex + 1);
    }, 1000); */
};

export const massivePreferenceActivationChange = () => {
    const tableInstance: any = getTableInstance();
    const selectedRows = tableInstance.getSelectedRowModel().rows;
    massivePreferenceChange(selectedRows, true);

    /* console.log(tableInstance);
    console.log(tableInstance.options.state.rowSelection);
    console.log(tableInstance.getSelectedRowModel().rows);
    console.log(tableInstance.getState()); */
}

export const massivePreferenceDisactivationChange = () => {
    const tableInstance: any = getTableInstance();
    const selectedRows = tableInstance.getSelectedRowModel().rows;
    massivePreferenceChange(selectedRows, false);
}

export function getColumnCfg() {
    const profilesState: any = getDataApiState();
    const profiles = profilesState?.get;
    const setProfiles = profilesState?.set;

    return {
        checkboxRow: false,
        massiveActions: [
            {
                name: 'massive-preference-activation',
                className: 'scale-100 stroke-2 fill-secondary-foreground stroke-secondary-foreground hover:fill-primary-foreground',
                iconLeft: ListTodoIcon,
                iconRight: Star,
                onClick: massivePreferenceActivationChange
            },
            {
                name: 'massive-preference-disactivation',
                className: 'scale-100 stroke-2 stroke-secondary-foreground hover:fill-secondary-foreground',
                iconLeft: ListTodoIcon,
                iconRight: Star,
                onClick: massivePreferenceDisactivationChange
            }
        ],
        columns: [
            {
                name: 'code',
                label: 'Codici',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                filterPlaceholder: 'Filtra i codici...',
                className: '',
                style: { maxWidth: '15vw' },
                icons: []
            },
            {
                name: 'name',
                label: 'Name',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                filterPlaceholder: 'Filtra i profili...',
                className: 'max-w-[100px]',
                style: { maxWidth: '10vw' },
                icons: [
                    {
                        name: 'personification',
                        label: 'Personificazione',
                        tooltip: true,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active scale-125 stroke-2 fill-primary stroke-secondary',
                        classNameConditionKey: 'personificated',
                        icon: CircleUser,
                        onClick: (rowOrigin: any, triggerName: string = '') => {
                            personificationChange(rowOrigin, triggerName);
                            globalRouter.push('/catalogue');
                        }
                    },
                    /* {
                        name: 'preference',
                        label: 'Preferenze',
                        tooltip: true,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active-2 scale-100 stroke-2 fill-primary stroke-primary',
                        classNameConditionKey: 'preferred',
                        icon: StarIcon,
                        onClick: preferenceChange
                    } */
                ]
            },
            {
                name: 'address',
                label: 'Indirizzo',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                filterPlaceholder: 'filtra l\'indirizzo...',
                className: 'max-w-[100px]',
                style: { maxWidth: '7vw' },
                icons: []
            },
            {
                name: 'city',
                label: 'Città',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                filterPlaceholder: 'filtra la città...',
                className: 'max-w-[100px]',
                style: { maxWidth: '5vw' },
                icons: []
            },
            {
                name: 'email',
                label: 'Email',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                filterPlaceholder: 'Filtra le mail...',
                className: 'max-w-[100px]',
                style: { maxWidth: '5vw' },
                icons: [
                    /* {
                        name: 'email',
                        label: 'email',
                        className: 'scale-100 stroke-1 stroke-secondary-foreground',
                        icon: MailIcon
                    }, */
                ]
            },
            {
                name: 'destination',
                label: 'Destinazione',
                type: 'base',
                enableSorting: true,
                enableHiding: true,
                filter: false,
                filterPlaceholder: 'Filtra le destinazioni...',
                style: { maxWidth: '8vw' },
                icons: [
                    {
                        name: 'destinations',
                        label: 'Destinazioni',
                        icon: ScanSearchIcon,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active scale-125 stroke-1 fill-primary stroke-secondary',
                        classNameConditionKey: '',
                        subMenu: false,
                        type: 'combobox',
                        placeholder: 'Cerca tra le destinazioni...',
                        tooltip: 'Seleziona la destinazione',
                        getCurrentOption: (customerId: string) => {
                            const currentUser = getCurrentUser();
                            const currentCustomer = currentUser?.customers?.filter((c: any) => c.id == customerId)?.[0];
                            let currentDestination = currentCustomer?.currentDestination;
                            return currentDestination;
                        },
                        getOptions: (customerId: string) => {
                            let destinations = getCurrentUser()?.customers?.filter((c: any) => c.id == customerId)?.[0]?.addressees;
                            destinations = destinations?.map((d: any) => {
                                return { ...d, name: d.description }
                            }); // map to combobox options
                            return destinations;
                        },
                        onOptionSelect: async (customerId: string, destinationId: string) => {
                            let currentUser = getCurrentUser();
                            const currentCustomer = currentUser?.customers?.filter((c: any) => c.id == customerId)?.[0];
                            if (currentCustomer.personificated === false) {
                                currentUser = await personificationChange(currentCustomer);
                            }
                            let destination = currentCustomer?.addressees.filter((d: any) => d.id == destinationId)?.[0];
                            currentUser.currentDestination = destination;
                            currentUser.customers = currentUser?.customers?.map((customer: any) => {
                                if (customer.id === customerId) {
                                    return {
                                        ...customer,
                                        destination: destination?.description,
                                        currentDestination: destination
                                    }
                                }
                                return customer;
                            })
                            setCookie('CURRENT_USER', JSON.stringify(currentUser));
                            if (typeof window !== 'undefined') {
                                localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));
                            }
                            const apiState: any = getDataApiState();
                            apiState?.set(currentUser.customers);
                            setPersonification(currentUser);
                            if (getTableInstance()) {
                                rerenderTable();
                            }
                            await timeout(1000);
                            globalRouter.push('/catalogue');
                        },
                        onClick: () => {
                            setBF([])

                        }
                    }
                    /* {
                        name: 'destinations',
                        label: 'Destinazioni',
                        tooltip: true,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active scale-125 stroke-2 fill-primary stroke-secondary',
                        classNameConditionKey: 'destination',
                        icon: ScanSearchIcon,
                        onClick: () => { }
                    } */
                ]
            },
            /* {
                name: 'role',
                label: 'Ruolo',
                type: 'filter',
                enableSorting: true,
                enableHiding: true,
                filter: true,
                options: [
                    {
                        name: "ADMIN",
                        label: "Amministratore",
                        icon: UserCogIcon,
                    },
                    {
                        name: "SUPERVISOR",
                        label: "Operatore",
                        icon: StampIcon,
                    },
                    {
                        name: "USER",
                        label: "Utente",
                        icon: AppWindowIcon,
                    },
                    {
                        name: "LIMITED",
                        label: "Limitato",
                        icon: SquareKanbanIcon,
                    }
                ]
            }, */
            /* {
                name: 'priority',
                label: 'Priorità',
                type: 'filter',
                enableSorting: true,
                enableHiding: true,
                filter: false,
                options: [
                    {
                        name: "very-high",
                        label: "Molto Alta",
                        icon: UserCogIcon,
                    },
                    {
                        name: "high",
                        label: "Alta",
                        icon: StampIcon,
                    },
                    {
                        name: "medium",
                        label: "Media",
                        icon: AppWindowIcon,
                    },
                    {
                        name: "low",
                        label: "Bassa",
                        icon: SquareKanbanIcon,
                    },
                    {
                        name: "very-low",
                        label: "Molto Bassa",
                        icon: UserIcon,
                    }
                ]
            }, */
            {
                name: 'actions',
                label: 'Actions',
                type: 'action',
                actions: [
                    {
                        name: 'personification',
                        label: 'Personificazione',
                        icon: CircleUser,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active scale-125 stroke-2 fill-primary stroke-secondary',
                        classNameConditionKey: 'personificated',
                        subMenu: false,
                        type: 'button',
                        onClick: (rowOrigin: any, triggerName: string = '') => {
                            personificationChange(rowOrigin, triggerName);
                            globalRouter.push('/catalogue');
                        }
                    },
                    {
                        name: 'destinations',
                        label: 'Destinazioni',
                        icon: ScanSearchIcon,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active scale-125 stroke-1 fill-primary stroke-secondary',
                        classNameConditionKey: '',
                        subMenu: false,
                        type: 'combobox',
                        tooltip: 'Seleziona la destinazione',
                        getCurrentOption: (customerId: string) => {
                            const currentUser = getCurrentUser();
                            const currentCustomer = currentUser?.customers?.filter((c: any) => c.id == customerId)?.[0];
                            let currentDestination = currentCustomer?.currentDestination;
                            return currentDestination;
                        },
                        getOptions: (customerId: string) => {
                            let destinations = getCurrentUser()?.customers?.filter((c: any) => c.id == customerId)?.[0]?.addressees;
                            destinations = destinations?.map((d: any) => {
                                return { ...d, name: d.description }
                            }); // map to combobox options
                            return destinations;
                        },
                        onOptionSelect: async (customerId: string, destinationId: string) => {
                            let currentUser = getCurrentUser();
                            const currentCustomer = currentUser?.customers?.filter((c: any) => c.id == customerId)?.[0];
                            if (currentCustomer.personificated === false) {
                                currentUser = await personificationChange(currentCustomer);
                            }
                            let destination = currentCustomer?.addressees.filter((d: any) => d.id == destinationId)?.[0];
                            currentUser.currentDestination = destination;
                            currentUser.customers = currentUser?.customers?.map((customer: any) => {
                                if (customer.id === customerId) {
                                    return {
                                        ...customer,
                                        destination: destination?.description,
                                        currentDestination: destination
                                    }
                                }
                                return customer;
                            })
                            setCookie('CURRENT_USER', JSON.stringify(currentUser));
                            if (typeof window !== 'undefined') {
                                localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));
                            }
                            const apiState: any = getDataApiState();
                            apiState?.set(currentUser.customers);
                            setPersonification(currentUser);
                            if (getTableInstance()) {
                                rerenderTable();
                            }
                            await timeout(1000);
                            globalRouter.push('/catalogue');
                        },
                        onClick: () => { setBF([]) }
                    },
                    /* {
                        name: 'preference',
                        label: 'Preferiti',
                        icon: StarIcon,
                        className: 'scale-100 stroke-2',
                        classNameActive: 'active-2 scale-100 stroke-2 fill-primary stroke-primary',
                        classNameConditionKey: 'preferred',
                        subMenu: false,
                        onClick: preferenceChange
                    }, */
                    {
                        name: 'personification',
                        label: 'Personifica',
                        conditionLabel: 'Esci dalla personificazione',
                        icon: CircleUser,
                        className: 'scale-100 stroke-2 fill-primary-foreground',
                        classNameActive: 'active scale-125 stroke-2 fill-secondary-foreground stroke-primary-foreground',
                        classNameConditionKey: 'personificated',
                        subMenu: true,
                        onClick: personificationChange
                    },
                    /* {
                        name: 'preference',
                        label: 'Aggiungi ai preferiti',
                        conditionLabel: 'Rimuovi dai preferiti',
                        icon: StarIcon,
                        className: 'scale-100 stroke-2 fill-primary-foreground',
                        classNameActive: 'active-2 scale-100 stroke-2 fill-secondary-foreground',
                        classNameConditionKey: 'preferred',
                        subMenu: true,
                        onClick: preferenceChange
                    } */
                ]
            }
        ]
    }
}

// Preview

const previewGalleryTemplate = (row: any, tableDictionary: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            <div className="w-full h-full">
                <ProductImagesManager />
            </div>
        </div>
    )
};

const previewInfoTemplate = (row: any, tableDictionary: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 overflow-auto w-full flex-grow">
                {Object.keys(row?.original).map((key, index) => {
                    return (
                        <div className="mb-2" key={key + '-' + index}>
                            <Label>{tableDictionary?.columns?.[key]?.label ?? key}</Label>
                            <Input value={row?.original[key] ?? ''} disabled={true} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

const previewDetailsTemplate = (row: any, tableDictionary: any) => {
    return (
        <div className="p-4 overflow-auto h-full">
            {Object.keys(row?.original).map((key, index) => {
                return (
                    <div className="mb-2" key={key + '-' + index}>
                        <Label>{key}</Label>
                        <Input value={row?.original[key] ?? ''} disabled={true} />
                    </div>
                );
            })}
        </div>
    )
};

export const getProfileDetailCfg = () => {
    return {
        tabs: [
            {
                name: 'gallery',
                label: 'Gallery',
                template: previewGalleryTemplate
            },
            {
                name: 'info',
                label: 'Info',
                template: previewInfoTemplate
            },
            {
                name: 'details',
                label: 'Details',
                template: previewDetailsTemplate
            }
        ]
    }
}
