'use client'

import React, { useRef } from 'react';
import { ResizablePanel, ResizablePanelGroup, ResizableHandle } from '@/components/ui/resizable';
import { ImperativePanelHandle } from "react-resizable-panels";

import { cn } from '@/lib/utils';
import { GenericObject } from '@/models/UtilsModel';
import Sidebar from '@/components/orchestrators/sidebar/Sidebar';


let isCollapsedData: GenericObject = { isCollapsed: false, setIsCollapsed: (condition: boolean) => { }, panelRef: null };

const getIsMobile = () => {
    let isMobile: any = true;
    if (typeof window !== 'undefined') {
        if (window.innerWidth <= 1024) {
            isMobile = true;
        } else {
            isMobile = false;
        }
        return isMobile;
    }
    return isMobile;
}

export function menuToggle(state: 'expand' | 'collapse' | null = null) {
    const isCollapsed = isCollapsedData.isCollapsed;
    const panel = isCollapsedData.panelRef?.current ?? null;
    if (state && panel) {
        if (state === 'expand') {
            isCollapsed ? panel.expand() : null;
        }

        if (state === 'collapse') {
            !isCollapsed ? panel.collapse() : null;
        }
    } else {
        if (panel) {
            if (isCollapsed) {
                panel.expand();
            } else {
                panel.collapse();
            }
        }
    };
}

function getCookie(name: string, document: GenericObject) {
    let cookie: GenericObject = {};
    document.cookie.split(';').forEach(function (el: string) {
        let split = el.split('=');
        cookie[split[0].trim()] = split.slice(1).join("=");
    })
    return cookie[name];
}

function MainLayout({
    children
}: Readonly<{
    children: React.ReactNode;
}>) {

    const leftPanelRef = useRef<ImperativePanelHandle>(null);

    const defaultLayout = [15, 85, 655];
    const navCollapsedSize = 0;

    const [_document, set_document] = React.useState<GenericObject | null>(null);

    React.useEffect(() => {
        set_document(document);
    }, [])

    React.useLayoutEffect(() => {
        //setIsTableView(getIsMobile());
        window.addEventListener('resize', () => {
            if (getIsMobile()) {
                isCollapsedData?.panelRef?.current?.collapse();
            } else {
                isCollapsedData?.panelRef?.current?.expand();
            }
        });
    }, []);

    const defaultCollapsed = (_document) ? getCookie('react-resizable-panels:collapsed', _document) : false;
    const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed);

    isCollapsedData = {
        isCollapsed: isCollapsed,
        setIsCollapsed: setIsCollapsed,
        panelRef: leftPanelRef
    };

    return (<ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                sizes
            )}`
        }}
        className="h-full items-stretch w-full grow"
    >
        <ResizablePanel
            ref={isCollapsedData.panelRef}
            defaultSize={isCollapsed ? navCollapsedSize : defaultLayout[0]}
            collapsedSize={navCollapsedSize}
            collapsible={true}
            minSize={navCollapsedSize}
            maxSize={15}
            onCollapse={() => {
                const panel = leftPanelRef.current;
                if (panel?.isCollapsed()) {
                    setIsCollapsed(true);
                    document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(true)}`;
                }
            }}
            onExpand={() => {
                const panel = leftPanelRef.current;
                if (!panel?.isCollapsed()) {
                    setIsCollapsed(false);
                    document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(false)}`;
                }
            }}
            className={cn(false && "min-w-[50px] max-w-[262px] transition-all duration-300 ease-in-out")}
        >
            <Sidebar isCollapsed={isCollapsed}></Sidebar>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
            className='flex flex-col'
            defaultSize={defaultLayout[1]}
            minSize={30}
        >
            {children}
        </ResizablePanel>
    </ResizablePanelGroup>
    );
}

export default MainLayout;
