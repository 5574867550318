'use client';

import { getNavMenuStates } from "@/components/orchestrators/nav-menu/NavMenu"
import { LinkProps, NavigationItemsProps } from "@/models/NavigationModel";

import {
    AlertCircle,
    Archive,
    ArchiveX,
    File,
    Inbox,
    MessagesSquare,
    PenBox,
    Search,
    Send,
    ShoppingCart,
    Trash2,
    Users2,
    Home,
    Gauge,
    Sidebar,
    Container,
    UsersIcon,
    InfoIcon,
    ContactIcon,
    ShoppingCartIcon,
    LeafIcon
} from "lucide-react";
import { any } from "zod";
import axiosInterceptorInstance from "../axiosInterceptorInstance";
import path from "path";
import axios from "axios";

export const getCatalogueTreeData = async () => {
    const recursiveTreeMapper = (tree: any) => {
        const treeList = Object.entries(tree);
        const newTree = treeList.map((entry: any) => entry[1]);
        return newTree.map((node: any) => {
            const newNode: any = {
                id: node.id,
                name: node.description,
                children: []
            }
            if (node.children) {
                newNode.children = recursiveTreeMapper(node.children);
            }
            return newNode;
        });
    }

    try {
        const categoryTreeUrl: string = (
            (process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') + ('/categories')
        );
        const response = await axiosInterceptorInstance.get(categoryTreeUrl); // Replace with your API endpoint
        //const response = await axios.get('/data/tree_data.json'); // Replace with your API endpoint
        // Handle the response data here
        if (response?.data?.response) {
            const data = recursiveTreeMapper(response?.data?.response) ?? null;
            return data;
        } else {
            console.log('No data found');
        }
    } catch (error) {
        // Handle the error here
        console.error(error);
        return null;
    }
}

const components: LinkProps[] = [
    {
        id: '1',
        name: "dashboard",
        label: "Dashboard",
        href: "/dashboard/",
        description:
            "A dashboard is a set of pages that are easy to read and offer information to the user.",
    },
    {
        id: '2',
        name: "about",
        label: "About us",
        href: "/about/",
        description:
            "A dashboard is a set of pages that are easy to read and offer information to the user.",
    },
    {
        id: '3',
        name: "contact",
        label: "Contact",
        href: "/contact/",
        description:
            "A contact page is a set of pages that are easy to read and offer information to the user.",
    },
    /* {
        id: '4',
        name: "Scroll-area",
        label: "Alert Dialog",
        href: "/docs/primitives/scroll-area",
        description: "Visually or semantically separates content.",
    },
    {
        id: '5',
        name: "Tabs",
        label: "Alert Dialog",
        href: "/docs/primitives/tabs",
        description:
            "A set of layered sections of content—known as tab panels—that are displayed one at a time.",
    },
    {
        id: '6',
        name: "Tooltip",
        label: "Alert Dialog",
        href: "/docs/primitives/tooltip",
        description:
            "A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.",
    }, */
]

const navigationList: NavigationItemsProps[] = [
    {
        id: '1',
        name: "mega_menu",
        label: "Mega menu",
        specialChild: {
            id: '3',
            name: "personification",
            label: "Personification",
            href: "/personification/page/1/size/10/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
        },
        children: components
    },
    {
        id: '2',
        name: "sub_menu",
        label: "Sub menu",
        children: components
    },
    {
        id: '3',
        name: "dashboard",
        label: "Dashboard",
        href: "/dashboard/",
    },
    {
        id: '4',
        name: "contact",
        label: "Contact",
        href: "/contact",
    },
    {
        id: '5',
        name: "about",
        label: "About us",
        href: "/about/",
    }
]

const navigationList2: NavigationItemsProps[] = [
    {
        id: '6',
        name: "mega_menu",
        label: "Mega menu",
        specialChild: {
            id: '1',
            name: "personification",
            label: "Personification",
            href: "/personification/page/1/size/10/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
        },
        children: components
    },
    {
        id: '7',
        name: "mega_menu_2",
        label: "Mega menu 2",
        specialChild: {
            id: '2',
            name: "personification",
            label: "Personification",
            href: "/personification/page/1/size/10/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
        },
        children: components
    },
    {
        id: '8',
        name: "sub_menu",
        label: "Sub menu",
        children: components
    },
    {
        id: '9',
        name: "sub_menu_2",
        label: "Sub menu 2",
        children: components
    },
    {
        id: '10',
        name: "link",
        label: "Link",
        href: "/"
    },
    {
        id: '11',
        name: "link_2",
        label: "Link 2",
        href: "/"
    },
    {
        id: '12',
        name: "link_3",
        label: "Link 3",
        href: "/"
    }
]

export const sidebarNavigationList = [
    {
        id: '1',
        label: "Home",
        name: "home",
        icon: Home,
        variant: "ghost",
        href: "/"
    }
]

const sidebarNavigationList2: any[] = [
    /* {
        id: '7',
        label: "Social",
        name: "social",
        icon: Users2,
        variant: "ghost",
    },
    {
        id: '8',
        label: "Updates",
        name: "updates",
        icon: AlertCircle,
        variant: "ghost",
    },
    {
        id: '9',
        label: "Forums",
        name: "forums",
        icon: MessagesSquare,
        variant: "ghost",
    },
    {
        id: '10',
        label: "Shopping",
        name: "shopping",
        icon: ShoppingCart,
        variant: "ghost",
    },
    {
        id: '11',
        label: "Promotions",
        name: "promotions",
        icon: Archive,
        variant: "ghost",
    }, */
]

const subMenuList1: any[] = [
    {
        id: '1',
        label: "Category 01 Sub 01",
        name: "category-1-sub-1",
        icon: Container,
        variant: "ghost",
        specialChild: {
            id: '1',
            name: "category-1-sub-1",
            label: "Category 01 Sub 01",
            href: "/catalogue/category/1/sub/1/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
            click: onSidebarClickHandler('/catalogue/category/1/sub/1/')
        },
        children: [
            {
                id: '1',
                name: "category-1-sub-1-list-01",
                label: "Category 01 Sub 01 List 01",
                href: "/catalogue/category/1/sub/1/list/1/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/1/list/1')
            },
            {
                id: '2',
                name: "category-1-sub-1-list-02",
                label: "Category 01 Sub 01 List 02",
                href: "/catalogue/category/1/sub/1/list/2/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/1/list/2')
            },
            {
                id: '3',
                name: "category-1-sub-1-list-03",
                label: "Category 01 Sub 01 List 03",
                href: "/catalogue/category/1/sub/1/list/3/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/1/list/3')
            }
        ]
    },
    {
        id: '1',
        label: "Category 01 Sub 02",
        name: "category-1-sub-2",
        icon: Container,
        variant: "ghost",
        href: "/catalogue/category/1/sub/2/",
        specialChild: {
            id: '1',
            name: "category-1-sub-2",
            label: "Category 01 Sub 02",
            href: "/catalogue/category/1/sub/2/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
            click: onSidebarClickHandler('/catalogue/category/1/sub/2/')
        },
        children: [
            {
                id: '1',
                name: "category-1-sub-2-list-01",
                label: "Category 01 Sub 01 List 01",
                href: "/catalogue/category/1/sub/1/list/1/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/2/list/1')
            },
            {
                id: '2',
                name: "category-1-sub-2-list-02",
                label: "Category 01 Sub 01 List 02",
                href: "/catalogue/category/1/sub/1/list/1/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/2/list/2')
            },
            {
                id: '1',
                name: "category-1-sub-2-list-01",
                label: "Category 01 Sub 01 List 01",
                href: "/catalogue/category/1/sub/1/list/1/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/1/sub/2/list/2')
            }
        ]
    }
]

const subMenuList2: any[] = [
    {
        id: '1',
        label: "Category 02 Sub 01",
        name: "category-2-sub-1",
        icon: Container,
        variant: "ghost",
        specialChild: {
            id: '1',
            name: "category-2-sub-1",
            label: "Category 02 Sub 01",
            href: "/catalogue/category/2/sub/1/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
            click: onSidebarClickHandler('/catalogue/category/2/sub/1/')
        },
        children: [
            {
                id: '1',
                name: "category-2-sub-1-list-01",
                label: "Category 02 Sub 01 List 01",
                href: "/catalogue/category/2/sub/1/list/1/",
                description:
                    "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/1/list/1')
            },
            {
                id: '2',
                name: "category-2-sub-1-list-02",
                label: "Category 02 Sub 01 List 02",
                href: "/catalogue/category/2/sub/1/list/2/",
                description:
                    "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/1/list/1')
            },
            {
                id: '3',
                name: "category-2-sub-1-list-03",
                label: "Category 02 Sub 01 List 03",
                href: "/catalogue/category/2/sub/1/list/3/",
                description:
                    "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/1/list/1')
            }
        ]
    },
    {
        id: '1',
        label: "Category 02 Sub 02",
        name: "category-2-sub-2",
        icon: Container,
        variant: "ghost",
        specialChild: {
            id: '1',
            name: "category-2-sub-2",
            label: "Category 02 Sub 02",
            href: "/catalogue/category/2/sub/2/",
            description: "Management of user personification data and user personification data management system. Accessible. Customizable. Open Source.",
            click: onSidebarClickHandler('/catalogue/category/2/sub/2/')
        },
        children: [
            {
                id: '1',
                name: "category-2-sub-2-list-01",
                label: "Category 02 Sub 01 List 01",
                href: "/catalogue/category/2/sub/2/list/1/",
                description: "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/2/list/1')
            },
            {
                id: '2',
                name: "category-2-sub-2-list-02",
                label: "Category 02 Sub 01 List 02",
                href: "/catalogue/category/2/sub/2/list/2/",
                description:
                    "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/2/list/1')
            },
            {
                id: '3',
                name: "category-2-sub-2-list-03",
                label: "Category 02 Sub 01 List 03",
                href: "/catalogue/category/2/sub/2/list/3/",
                description:
                    "A dashboard is a set of pages that are easy to read and offer information to the user.",
                click: onSidebarClickHandler('/catalogue/category/2/sub/2/list/1')
            }
        ]
    }
]

export const workspaceNavigationList = [
    /* {
        id: '1',
        label: "Back office",
        name: "backoffice",
        icon: Home,
        variant: "ghost",
        href: "/backoffice/"
    }, */
    {
        id: '2',
        label: "Amministrazione",
        name: "administration",
        icon: UsersIcon,
        variant: "ghost",
        href: "/administration/"
    },
    {
        id: '3',
        label: "Catalogo",
        name: "categories",
        icon: Container,
        href: "/catalogue/",
        variant: "ghost"
    }
]

const backofficeNavigationList = [
    {
        id: 'backoffice-1',
        label: "Back office",
        name: "backoffice",
        icon: Home,
        variant: "ghost",
        href: "/backoffice/",
        defaultValue: '/backoffice/',
        state: 'open',
        collapsible: false,
        active: true,
        children: [
            {
                id: '1',
                label: "Utenti",
                name: "users",
                icon: UsersIcon,
                variant: "ghost",
                href: "/backoffice/users/"
            },
            {
                id: '2',
                label: "Clienti",
                name: "clients",
                icon: Container,
                href: "/backoffice/clients/",
                variant: "ghost"
            },
            {
                id: '3',
                label: "Fornitori",
                name: "suppliers",
                icon: Container,
                href: "/backoffice/suppliers/",
                variant: "ghost"
            },
            {
                id: '4',
                label: "Contestazioni",
                name: "controversy",
                icon: Container,
                href: "/backoffice/controversy/",
                variant: "ghost"
            },
            {
                id: '5',
                label: "Storico ordini",
                name: "order-history",
                icon: Container,
                href: "/backoffice/order-history/",
                variant: "ghost"
            },
            {
                id: '6',
                label: "Storico consegne",
                name: "delivery-history",
                icon: Container,
                href: "/backoffice/delivery-history/",
                variant: "ghost"
            },
            {
                id: '7',
                label: "Elenco fatture e nota credito",
                name: "invoices-list-and-credit-notes",
                icon: Container,
                href: "/backoffice/invoices-list-and-credit-notes/",
                variant: "ghost"
            },
            {
                id: '8',
                label: "Gestione campagne e programmazioni",
                name: "campaign-management-and-programming",
                icon: Container,
                href: "/backoffice/campaign-management-and-programming/",
                variant: "ghost"
            },
            {
                id: '9',
                label: "Raccolta anagrafiche Clienti",
                name: "customer-record-collection",
                icon: Container,
                href: "/backoffice/customer-record-collection/",
                variant: "ghost"
            }
        ]
    }
]

const administrationNavigationList = [
    {
        id: 'administration-1',
        label: "Amministrazione",
        name: "administration",
        icon: Home,
        variant: "ghost",
        href: "/administration/",
        defaultValue: '/administration/',
        state: 'open',
        collapsible: false,
        active: true,
        children: [
            {
                id: '1',
                label: "Ordini da inivare",
                name: "send-orders",
                icon: UsersIcon,
                variant: "ghost",
                href: "/administration/send-orders/"
            },
            {
                id: '2',
                label: "Campagne attive",
                name: "active-campaign",
                icon: Container,
                href: "/administration/active-campaign/",
                variant: "ghost"
            },
            {
                id: '3',
                label: "Programmazione ordini",
                name: "order-scheduling",
                icon: Container,
                href: "/administration/order-scheduling/",
                variant: "ghost"
            },
            {
                id: '4',
                label: "Contestazioni",
                name: "controversy",
                icon: Container,
                href: "/administration/controversy/",
                variant: "ghost"
            },
            {
                id: '5',
                label: "Storico ordini",
                name: "order-history",
                icon: Container,
                href: "/administration/order-history/",
                variant: "ghost"
            },
            {
                id: '6',
                label: "Storico consegne",
                name: "delivery-history",
                icon: Container,
                href: "/administration/delivery-history/",
                variant: "ghost"
            },
            {
                id: '7',
                label: "Elenco fatture e nota credito",
                name: "invoices-list-and-credit-notes",
                icon: Container,
                href: "/administration/invoices-list-and-credit-notes/",
                variant: "ghost"
            },
            {
                id: '8',
                label: "Partitario",
                name: "register",
                icon: Container,
                href: "/administration/register/",
                variant: "ghost"
            },
            {
                id: '9',
                label: "Riba",
                name: "shore",
                icon: Container,
                href: "/administration/shore/",
                variant: "ghost"
            },
            {
                id: '11',
                label: "Variazione prezzi",
                name: "price-change",
                icon: Container,
                href: "/administration/price-change/",
                variant: "ghost"
            },
            {
                id: '12',
                label: "Download",
                name: "download",
                icon: Container,
                href: "/administration/download/",
                variant: "ghost"
            },
            {
                id: '13',
                label: "Stampa cartelli",
                name: "sign-printing",
                icon: Container,
                href: "/administration/sign-printing/",
                variant: "ghost"
            },
            {
                id: '14',
                label: "Conferma ordine parzie strike",
                name: "strike-partial-order-confirmation",
                icon: Container,
                href: "/administration/strike-partial-order-confirmation/",
                variant: "ghost"
            },
            {
                id: '15',
                label: "Interrogazione fido",
                name: "credit-line-query",
                icon: Container,
                href: "/administration/credit-line-query/",
                variant: "ghost"
            }
        ]
    }
]

export function onSidebarClickHandler(href: string | null) {
    let result = () => { };
    switch (href) {
        case '/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: navigationList });
            break;
        case '/dashboard/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: navigationList2 });
            break;
        case '/catalogue/category/1/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList1 });
            break;
        case '/catalogue/category/1/sub/1/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList1 });
            break;
        case '/catalogue/category/1/sub/2/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList1 });
            break;
        case '/catalogue/category/1/sub/3/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList1 });
            break;
        case '/catalogue/category/1/sub/1/list/1/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList1 });
            break;
        case '/catalogue/category/2/':
            result = () => getNavMenuStates()?.setDataProps({ navigationList: subMenuList2 });
            break;
        default:
            result = () => getNavMenuStates()?.setDataProps({ navigationList: navigationList });
            break;
    }

    return result
}

export function onSidebarClickHandler2() {
    return async (params: any = {}, pathName: string | null = null, router: any = null) => {
        let result = null;

        if (pathName === '/backoffice/') {
            return await NavigationService('backoffice', params);
        }

        if (pathName === '/administration/') {
            return await NavigationService('administration', params);
        }

        if (pathName === '/catalogue/') {
            return await NavigationService('catalogue', { ...params });
        }

        /* console.log(pathName?.replace(/(^[\/])|([\/]$)/g, "").split('/')); */

        return await NavigationByPathName(pathName);
    }
}

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function getSidebarNavigationList() {
    await timeout(3000);
    const newSnl = sidebarNavigationList;
    return newSnl;
}

export async function NavigationService(workspace: string | null = null, params: any) {
    let treeData: any = [];
    switch (workspace) {
        case 'catalogue':
            const localTreeData = localStorage.getItem('CATALOGUE_TREE_DATA');
            if (localTreeData) {
                treeData = JSON.parse(localTreeData ?? '');
            } else {
                treeData = await getCatalogueTreeData();
                treeData ? localStorage.setItem('CATALOGUE_TREE_DATA', JSON.stringify(treeData)) : null;
            }
            if (params) {
                const paramsList = Object.entries(params);
                treeData = getTreeLevel(treeData, paramsList);
            }
            break;
        case 'backoffice':
            treeData = backofficeNavigationList;
            break
        case 'administration':
            treeData = administrationNavigationList;
            break
        default:
            treeData = workspaceNavigationList;
            break;
    }

    return treeData;
}

export async function NavigationByPathName(pathName: string | null) {
    let treeData: any = [];
    const uriParams = pathName?.replace(/(^[\/])|([\/]$)/g, "").split('/');

    switch (uriParams?.[0]) {
        case 'backoffice':
            treeData = JSON.parse(JSON.stringify(backofficeNavigationList));
            break
        case 'administration':
            treeData = JSON.parse(JSON.stringify(administrationNavigationList));
            break
        default:
            treeData = workspaceNavigationList;
            break;
    }

    const recursiveTreeMapper = (node: any, params: string[] = [''], index: number = 0) => {

        const current = node.find((x: any) => x.name === params?.[index]);

        if (!current && (index < params?.length)) {
            return recursiveTreeMapper(node, params, ++index)
        }

        if (uriParams?.[index + 1]) {

            if (current?.children) {
                current.children = recursiveTreeMapper(current.children, uriParams, ++index);
            }
        }

        return [current];
    }

    treeData = recursiveTreeMapper(treeData, uriParams);

    return treeData;
}

interface TreeNode {
    id: number;
    name: string;
    children?: TreeNode[];
}

export function findCategoryNodeById(tree: TreeNode[] = [], id: number): TreeNode | undefined {
    for (let node of tree) {
        // Check if the current node matches the id
        if (node.id === id) {
            return node;
        }

        // If the node has children, recursively search within them
        if (node.children) {
            const result = findCategoryNodeById(node.children, id);
            if (result) {
                return result;
            }
        }
    }
}

const getTreeLevelNew = (treeData: any[], paramsList: any) => {
    let tree: any[] = [];

    /* tree = [{
        id: 'XXXXX',
        label: "Catalogo",
        name: "categories",
        icon: Container,
        href: link[0].href,
        hrefPattern: link[0].hrefPattern,
        variant: "ghost",
        defaultValue: 'XXXXX',
        state: 'open',
        collapsible: false,
        active: true,
        children: tree
    }];

    return tree; */
};

const getTreeLevel = (treeData: any[], paramsList: any) => {
    let tree: any[] = [];
    const newParamsList = paramsList.filter((x: any) => (x[0] == 'workspace' || x[0] == 'category' || x[0] == 'sub'));

    let link = newParamsList.reduce((acc: { href: any, hrefPattern: string }[], [key, value]: any, index: number) => {
        let temp: any = acc[index] || { ...acc[index - 1] };
        temp.hrefPattern = temp.hrefPattern + (key + '/[' + key + ']/');
        /* temp.href = temp.href + key + '/' + value + '/'; */
        temp.href = temp.href + ((index == 0) ? '?' : '&') + key + '=' + value;
        acc[index] = temp;
        return acc;
    }, [{ href: '/catalogue/', hrefPattern: '/catalogue/' }]);

    link = [{ href: '/catalogue/', hrefPattern: '/catalogue/' }]

    if ((newParamsList.length == 0) && Array.isArray(treeData)) {
        tree = treeData?.map((item: any) => {
            item.label = item.name;
            item.icon = LeafIcon;
            item.variant = "ghost";
            item.href = (link[0].href + '?category=' + item.id /* + 'page/1/size/10/' */);
            item.hrefPattern = (link[0].hrefPattern + "/[category]/" /* + 'page/[page]/size/[size]/' */);
            item.children = [];
            return item;
        });
    }

    if (newParamsList.length == 1 || newParamsList.length == 2) {
        const item = (treeData?.filter((x: any) => (x.id == newParamsList[0][1])))?.[0];
        tree = [{
            id: item?.id,
            label: item?.name,
            name: item?.name,
            icon: Container,
            href: (link[0].href + '?category=' + item?.id /* + 'page/1/size/10/' */),
            hrefPattern: (link[0].hrefPattern + "/[category]/" /* + 'page/[page]/size/[size]/' */),
            variant: "ghost",
            defaultValue: item?.href,
            state: 'open',
            collapsible: false,
            active: true,
            children: item?.children?.map((item2: any) => {
                item2.label = item2.name;
                item2.icon = LeafIcon;
                item2.variant = "ghost";
                item2.href = (link[0].href + '?category=' + item?.id + '&sub=' + item2.id) + '&page=1&pageSize=10';
                item2.hrefPattern = (link[0].hrefPattern + "/[category]/" + "sub/[sub]/" + 'page/[page]/size/[size]/');
                item2.children = [];
                return item2;
            })
        }];

        if (newParamsList.length == 2) {
            const sub = ((item?.children.filter((x: any) => (x.id == newParamsList[1][1])))?.[0]);
            tree[0].children = [{
                id: sub?.id,
                label: sub?.name,
                name: sub?.name,
                icon: Container,
                href: (link[0].href + '?category=' + item?.id + '&sub=' + sub?.id) + '&page=1&pageSize=10',
                hrefPattern: (link[0].hrefPattern + "/[category]/" + "sub/[sub]/" + 'page/[page]/size/[size]/'),
                variant: "ghost",
                defaultValue: sub?.id,
                state: 'close',
                collapsible: false,
                active: true,
                children: []
            }];
        }
    }

    tree = [{
        id: 'XXXXX',
        label: "Catalogo",
        name: "categories",
        icon: Container,
        href: link[0].href,
        hrefPattern: link[0].hrefPattern,
        variant: "ghost",
        defaultValue: 'XXXXX',
        state: 'open',
        collapsible: false,
        active: true,
        children: tree
    }];

    return tree;
};
