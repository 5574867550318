"use client"

import * as React from "react"
import "../CoolTable.styles.css"
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import { DataTablePagination } from "./data-table-pagination"
import { DataTableToolbar } from "./data-table-toolbar"
import { getColumns } from "./columns"
import { GenericObject } from "@/models/UtilsModel"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useRouter } from "next/router"
import { DataCards } from "./data-cards"
import { DataTableSheet, dataTableSheetModel } from "@/components/orchestrators/cool-table/components/data-table-sheet"
import { CircleCheckIcon, LoaderCircleIcon } from "lucide-react"
import { DataTableRowActions } from "./data-table-row-actions"
import { DataProductCards } from "./data-product-cards"
import useSWR from "swr"
import { getFilterCategoryById } from "@/services/api/category.service"
import { setAV, setBF, setGF, setSelectedF, setSF } from "@/components/presentational/FilterAccordion"
import { setgid } from "process"

interface DataTableProps<TData> {
    data: TData[],
    columnsCfg: GenericObject,
    previewCfg?: GenericObject,
    tableDictionary?: any,
    defaultPageSize?: number,
    manualPagination?: boolean,
    fetchData?: any,
    view?: 'table' | 'cards',
    enableMassActions?: boolean,
}

export let getTableInstance: any = () => null;

export let rerenderTable = () => { };

export let getDataApiState = () => { };

export let getItemSelection = () => { };

export let getDataTable: any = () => { };
export let setDataTable: any = async () => { };

export let getFilterCategory: any = () => { };
export let setFilterCategory: any = () => { };

export let setCF: any = () => { };

let lastIntegratedPayload: any = {};
let setTimeoutFetchDataID: any = null;

let lastRandom = 0;

export function DataTable<TData>({
    data,
    columnsCfg,
    previewCfg,
    tableDictionary,
    defaultPageSize = 10,
    manualPagination = false,
    fetchData = null,
    view = 'table',
    enableMassActions = true,
}: DataTableProps<TData>) {

    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [itemSelection, setItemSelection]: any = React.useState({});

    const columns = getColumns(columnsCfg, tableDictionary?.columns);

    const router: any = useRouter();

    const currentPage = parseInt(router?.query?.page ?? 0, 10);
    const currentPageSize = parseInt(router?.query?.pageSize ?? defaultPageSize, 10);
    const searchText = router?.query?.searchText;
    const subCategory = router?.query?.sub;
    const [pagination, setPagination]: any = React.useState({});

    const [dataApi, setDataApi] = React.useState(data);
    const [searchPayload, setSearchPayload] = React.useState({});
    const [pageCount, setPageCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const [categoryFilters, setCategoryFilters]: any = React.useState({ data: null });
    setCF = setCategoryFilters;

    async function fetchDataFromApi(pagination: any = null, payload: any = {}) {
        if (!fetchData) return;
        setIsLoading(true);
        setSearchPayload(payload);
        const result = await fetchData({
            page: (pagination?.pageIndex === undefined) ? currentPage : (pagination?.pageIndex + 1),
            per_page: pagination?.pageSize ?? pagination?.[0]?.pageSize ?? defaultPageSize,
            search: searchText,
            ...payload
        });

        if (columnsCfg?.onDataTable) {
            await columnsCfg?.onDataTable(payload, router);
        }

        /* const categoryFilters = await getFilterCategoryById(router?.query?.sub ?? router?.sub, payload);
        setCategoryFilters({
            data: { ...categoryFilters }
        }) */

        setIsLoading(false);
        return result;
    };

    const [dataTable, setDT]: any = React.useState([]);

    /* const dataTable = useSWR({ pageIndex: currentPage - 1, pageSize: currentPageSize, searchText: searchText }, fetchDataFromApi); */

    getDataTable = () => dataTable.data;

    setDataTable = async (payload: any, callback?: any) => {
        clearTimeout(setTimeoutFetchDataID);
        lastRandom = Math.floor(Math.random() * 10000);
        setTimeoutFetchDataID = await setTimeout(async () => {
            const asyncRandom = lastRandom;
            lastIntegratedPayload = { ...lastIntegratedPayload, ...payload };
            const result = await fetchDataFromApi(pagination, lastIntegratedPayload);
            if (asyncRandom === lastRandom) {
                setDataApi(result?.data);
                const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                setPageCount(manualPagination ? -1 : pageLength);
                callback?.();
            }
            setIsLoading(false);
        }, 1100);
    };

    getFilterCategory = () => categoryFilters.data;
    setFilterCategory = (data: any) => setCategoryFilters(data);

    const cardsTemplate = columnsCfg.cardsTemplate ?? 'standard';

    getDataApiState = () => {
        return { get: dataApi, set: setDataApi };
    }

    getItemSelection = () => {
        return { get: itemSelection, set: setItemSelection };
    };

    React.useEffect(() => {
        return () => {
            setDataApi([]);
            setFilterCategory({ data: null });
            setBF([]);
            setSF([]);
            setGF([]);
            setSelectedF([]);
            setSearchPayload({});
            lastIntegratedPayload = {};
        };
    }, []);

    React.useEffect(() => {
        (async () => {
            const data = await fetchDataFromApi({ pageIndex: currentPage - 1, pageSize: currentPageSize, searchText: searchText });
            setDT({ data: data });
            setAV('');
        })()
    }, [searchText]);

    React.useEffect(() => {
        if (categoryFilters?.data?.brands?.length > 0) {
            setBF(categoryFilters?.data?.brands);
        }
        if (categoryFilters?.data?.grids?.length > 0) {
            setGF(categoryFilters?.data?.grids);
        }
        if (categoryFilters?.data?.specs?.length > 0) {
            console.log('categoryFilters?.data?.specs', categoryFilters?.data?.specs);
            setSF(categoryFilters?.data?.specs);
        }
    }, [categoryFilters?.data?.brands, categoryFilters?.data?.specs]);

    React.useEffect(() => {
        (async () => {
            if (currentPage && currentPageSize && dataTable?.data) {
                if (dataApi?.length === 0) {
                    const result = dataTable?.data;
                    setDataApi(result?.data);
                    const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                    setPageCount(manualPagination ? -1 : pageLength);
                    setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: manualPagination ? false : true });
                    //setIsLoading(false);
                } else {
                    const result = dataTable?.data;
                    setDataApi(result?.data);
                    if (manualPagination) {
                        const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                        setPageCount(manualPagination ? -1 : pageLength);
                    }
                    setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: false });
                    setPageCount(manualPagination ? -1 : Math.ceil(dataApi.length / currentPageSize));

                    /* if (manualPagination) {
                        const result = dataTable?.data;
                        setDataApi(result?.data);
                        const pageLength: number = (pagination?.[0]?.pageSize) ? Math.ceil(result?.data.length / (pagination?.[0]?.pageSize ?? defaultPageSize)) : Math.ceil(result?.data?.length / (pagination?.pageSize ?? defaultPageSize));
                        setPageCount(manualPagination ? -1 : pageLength);
                        //setIsLoading(false);
                    }
                    setPagination({ pageIndex: currentPage - 1, pageSize: currentPageSize, firstTime: false });
                    setPageCount(manualPagination ? -1 : Math.ceil(dataApi.length / currentPageSize)); */
                }
            }
        })();
    }, [currentPage, currentPageSize, subCategory, dataTable?.data]);

    React.useEffect(() => {
        if (itemSelection?.original?.id) {
            dataTableSheetModel.setOpen(true);
        } else {
            dataTableSheetModel.setOpen(false);
        }
    }, [itemSelection])

    const table = useReactTable({
        data: (fetchData) ? dataApi : data,
        columns,
        pageCount: pageCount,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination: pagination,
        },
        enableRowSelection: true,
        manualPagination: manualPagination,
        enableSortingRemoval: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onPaginationChange: async (updater) => {
            console.log('onPaginationChange', updater, pagination);
            let newPagination = (typeof updater === 'function') ? updater(pagination) : updater;
            newPagination = (pagination.firstTime) ? pagination : newPagination;
            setPagination({ ...newPagination, firstTime: false });
            if (!manualPagination) {
                let pageLength: number = Math.ceil(data.length / (newPagination?.pageSize ?? defaultPageSize));
                setPageCount(pageLength);
            } else {
                setPageCount(-1);
            }
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    page: newPagination.pageIndex + 1,
                    pageSize: (newPagination?.pageSize ?? defaultPageSize),
                },
            });

            if (manualPagination) {
                const data = await fetchDataFromApi(newPagination, searchPayload);
                setDT({ data: data });
            };
        },
    })

    getTableInstance = () => table;

    rerenderTable = React.useReducer(() => ({}), {})[1];

    const handleItemSelection = (row: any) => {
        if (row.original?.id === itemSelection?.original?.id) {
            setItemSelection({});
        } else {
            setItemSelection(row);
        }
    }

    const actionsTemplate = (row: any, columnsCfg: any, tableColumnsDictionary: any = null) => {
        const columnActions = columnsCfg?.columns.filter((column: any) => column.name === 'actions')[0];
        return (
            <div className="flex flex-row justify-between items-center">
                <label htmlFor="" className="text-foreground font-bold">Actions:</label>
                <div className="flex items-center space-x-2">
                    {
                        columnActions?.actions.filter((action: any) => !action.subMenu).map((action: any, index: number) => {
                            return (
                                <Tooltip key={action.name + '-' + index} delayDuration={0}>
                                    <TooltipTrigger>
                                        <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer block"} onClick={(e) => { e.stopPropagation(); action.onClick(row.original, action.name) ?? (() => { }) }}>
                                            {
                                                ((row.original?.status?.state === 'pending') && (action.name === row.original?.status?.name)) &&
                                                <LoaderCircleIcon name={action.name} size={action.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                            }
                                            {
                                                ((row.original?.status?.state === 'success') && (action.name === row.original?.status?.name)) &&
                                                <CircleCheckIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                            }
                                            {
                                                ((!row.original?.status?.state) || !(action.name === row.original?.status?.name)) &&
                                                <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original?.[action.classNameConditionKey]) && action.classNameActive)} />
                                            }
                                        </Badge>
                                    </TooltipTrigger>
                                    <TooltipContent side="bottom" className="flex items-center gap-4">
                                        {
                                            (() => {
                                                const tooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.tooltip ?? '';
                                                const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.disabledTooltip ?? '';
                                                return !(row.original?.[action.classNameConditionKey]) ? tooltip : disabledTooltip;
                                            })()
                                        }
                                    </TooltipContent>
                                </Tooltip>
                            )
                        })
                    }
                    <DataTableRowActions row={row} actions={columnActions.actions.filter((action: any) => action.subMenu)} />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-1 position-relative overflow-hidden">
            {
                (isLoading) ?
                    <div className="animate-gradient">
                        <img
                            fetchpriority="high"
                            src="/images/table_loading.png"
                            alt="Authentication"
                            className="block dark:hidden w-100 h-auto"
                        />
                    </div>
                    :
                    <div className="space-y-4 flex flex-col flex-1 m-1 w-full">
                        {
                            (enableMassActions) ?
                                <div className={'space-y-0' + ' ' + (table.getIsSomeRowsSelected() ? 'opaciy-100' : 'opacity-25 pointer-events-none')}>
                                    {
                                        columnsCfg?.massiveActions?.map((massiveAction: any, index: number) => {
                                            return (
                                                <Tooltip key={massiveAction.name + '-' + index} delayDuration={0}>
                                                    <TooltipTrigger>
                                                        <Badge variant="outline" className={"cursor-pointer mr-2"} onClick={() => { massiveAction.onClick() }}>
                                                            {(massiveAction?.iconLeft) ? <massiveAction.iconLeft size={massiveAction.size ?? 18} className={'mr-1' + ' ' + massiveAction.className} /> : null}
                                                            {(massiveAction?.icon) ? <massiveAction.icon size={massiveAction.size ?? 18} className={massiveAction.className} /> : null}
                                                            {(massiveAction?.iconRight) ? <massiveAction.iconRight size={massiveAction.size ?? 18} className={'ml-1' + ' ' + massiveAction.className} /> : null}
                                                        </Badge>
                                                    </TooltipTrigger>
                                                    <TooltipContent side="top" className="flex items-center gap-4">
                                                        {tableDictionary?.massiveActions?.[massiveAction.name]?.tooltip}
                                                    </TooltipContent>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </div>
                                :
                                null
                        }
                        <DataTableToolbar view={view} table={table} columns={columns} columnsCfg={columnsCfg} tableDictionary={tableDictionary} />
                        <div className="rounded-md border flex flex-1 overflow-hidden">
                            {
                                (view === 'table') ?
                                    <Table>
                                        <TableHeader className="z-10 bg-background top-0" style={{ position: 'sticky' }}>
                                            {table.getHeaderGroups().map((headerGroup: any) => (
                                                <TableRow key={headerGroup.id}>
                                                    {headerGroup.headers.map((header: any) => {
                                                        return (
                                                            <TableHead key={header.id} colSpan={header.colSpan}>
                                                                {header.isPlaceholder
                                                                    ? null
                                                                    : flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext()
                                                                    )}
                                                            </TableHead>
                                                        )
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableHeader>
                                        <TableBody>
                                            {table.getRowModel().rows?.length ? (
                                                table.getRowModel().rows.map((row: any) => (
                                                    <TableRow
                                                        key={row.id}
                                                        data-state={row.getIsSelected() && "selected"}
                                                        className={"cursor-pointer" + " " + (itemSelection?.original?.id === row.original?.id ? 'active-row' : '')}
                                                        onClick={() => { handleItemSelection(row) }}
                                                    >
                                                        {row.getVisibleCells().map((cell: any) => (
                                                            <TableCell key={cell.id}>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={columns.length}
                                                        className="h-24 text-center"
                                                    >
                                                        {tableDictionary?.messages?.noResults ?? 'No data'}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    :
                                    (() => {
                                        let cardsComponent = null;
                                        switch (cardsTemplate) {
                                            case 'standard':
                                                return cardsComponent = <DataCards enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}></DataCards>
                                            case 'products':
                                                return cardsComponent = <DataProductCards enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}></DataProductCards>
                                            default:
                                                return cardsComponent = <DataCards enableMassActions={enableMassActions} table={table} cardCfg={columnsCfg} tableDictionary={tableDictionary} itemSelection={itemSelection} itemSelectionFn={handleItemSelection}></DataCards>
                                        }
                                    })()
                            }
                        </div>
                        <DataTablePagination table={table} tableDictionary={tableDictionary} paginationState={pagination} />
                    </div>
            }
            <DataTableSheet
                side={(view === 'table') ? 'right' : 'top'}
                title={'ID: ' + itemSelection?.original?.id}
                description={''}
                actions={actionsTemplate(itemSelection, columnsCfg, tableDictionary?.columns)}
                data={itemSelection}
                onOpenCallback={() => { }}
                onCloseCallback={() => setItemSelection({})}
                previewCfg={previewCfg}
                tableDictionary={tableDictionary}
            ></DataTableSheet>
        </div>
    )
}
