import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Link from "next/link"
import { CheckIcon, ChevronRight, CircleUser, Frame, GoalIcon, Languages, Link2, LogOut, MapPinIcon, Menu, Package2, PanelLeftDashedIcon, Router, Search, ShoppingCart, Star, Target, Unplug, UserCogIcon, UsersIcon } from "lucide-react"

import { Button, buttonVariants } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { ModeToggle } from "./ModeToggle"

import { useRouter } from 'next/router'
import { TranslationsContext, updateDictionaryByLocale } from './providers/TranslationsProvider';
import { GenericObject } from '@/models/UtilsModel';
import { LinkProps } from '@/models/NavigationModel';

import { getCookies, setCookie, deleteCookie, getCookie } from 'cookies-next';
import { Separator } from './ui/separator';
import { getCurrentProfile, getCurrentUser, getCustomerProfiles, getDestinationList, getMainUser, getPreferredProfiles, personificationChange, setDestination } from '@/services/api/profiles.service';
import { logoutFetch, signOut } from '@/services/api/auth.service';
import { UserContext } from './providers/UserProvider';
import { StarFilledIcon } from '@radix-ui/react-icons';
import { DropdownMenuItemIndicator } from '@radix-ui/react-dropdown-menu';
import { useTheme } from 'next-themes';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './ui/command';
import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';
import { HeaderDropdownMenu } from './presentational/HeaderDropdownMenu';
import { workspaceNavigationList } from '@/services/api/navigation.service';
import { globalRouter } from '@/pages/_app';
import { ejectPersonification } from '@/pages/layout';

const currentUser = {
    name: 'Jhon Doe',
    email: 'jhon.doe@test.com',
    role: 'Amministratore'
}

const personificationUserList = [
    { name: 'Mario Rossi', email: 'example@test.com', role: 'Affiliato' },
    { name: 'Giuseppe Verdi', email: 'example@test.com', role: 'Affiliato' },
    { name: 'Michele Bianchi', email: 'example@test.com', role: 'Affiliato' }
]

function MainHeader({ ...props }) {
    const router = useRouter();
    const { pathname, asPath, query, locale } = router;
    const { user, setUser } = useContext(UserContext);

    const { dictionary }: GenericObject = useContext(TranslationsContext);

    const [favouriteList, setFavouriteList] = useState(getPreferredProfiles());
    const [customerList, setCustomerList] = useState(getCustomerProfiles());
    const [destinationList, setDestinationList] = useState(getDestinationList() ?? []);


    const { setTheme, themes, theme } = useTheme();

    const logout = async () => {
        await signOut();
    }

    console.log('router', router);

    const setLanguage = (value: string) => {
        router.push({ pathname, query }, asPath, { locale: value });
        setCookie('NEXT_LOCALE', value);
        updateDictionaryByLocale();
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        router.push({
            pathname: '/search',
            query: { searchText: event?.target?.[0]?.value, page: 1, pageSize: 10 },
        })
    }

    return (
        <header className="sticky top-0 flex h-[4.5rem] items-center gap-4 border-b bg-background pl-2 md:pl-4 z-30">
            {/* <Button onClick={props.onMenuButtonClick} size="icon" variant="outline">
                <PanelLeftDashedIcon className="h-4 w-10" />
            </Button> */}
            <div className='flex flex-row space-x-3 mb-[0.5rem]'>
                <span className='icon-unieuro text-2xl mt-1'></span>
                <Link href={(((user?.userInfo?.role === 'ADMIN') || user?.userInfo?.role === 'SUPERVISOR') && !user?.userInfo?.personificated) ? '/backoffice/' : '/catalogue/'} className='flex flex-row items-center'>
                    {/* <img src="https://static1.unieuro.it/medias/sys_master/root/h4f/h72/32818629902366/logoGray.png" alt="" className="w-auto h-9 object-contain" /> */}
                    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.63 82.01" className="w-auto h-7 object-contain fill-primary dark:stroke-primary-foreground">
                        <path className="cls-1" d="M154.31,55.67c.42,7.93,7.6,15.31,15.75,16.37,7.01.91,12.92-1.36,18.05-6.08.81-.74,1.84-1.42,2.88-1.69,2.12-.55,4.22.55,5.21,2.43.96,1.83.66,3.89-.93,5.53-4.59,4.72-9.99,8.01-16.59,9.03-10.64,1.65-20.01-.74-27.39-8.94-10.98-12.18-9.92-33.4,2.14-44.49,14.37-13.21,36.97-7.55,43.23,10.97,1.21,3.59,1.64,7.5,2.04,11.3.36,3.49-1.87,5.56-5.45,5.57-12.29.01-24.57,0-36.86,0-.65,0-1.29,0-2.06,0ZM188.03,46.24c-.95-10.1-8.38-16.73-17.56-16.15-8.16.52-16.89,9.41-15.93,16.15h33.49Z" />
                        <path className="cls-1" d="M362.63,53.17c-.46,10.8-5.88,20.71-17.85,25.91-11.89,5.17-23.18,3.33-32.86-5.45-12.17-11.04-12.6-31.53-1.16-43.63,11.39-12.04,30.61-12.58,42.67-1.07,6.3,6.01,9.18,13.55,9.2,24.23ZM312.68,51.06c.19,1.61.27,3.24.58,4.83,2.89,14.67,19.87,21.38,31.34,12.27,6.6-5.24,8.63-12.41,7.49-20.52-1.67-11.82-12.58-19.51-23.97-17.01-9.17,2.02-15.32,10.21-15.45,20.43Z" />
                        <path className="cls-1" d="M49.36,43.13c0,5.05.13,10.1-.03,15.14-.29,9.23-3.83,16.78-12.47,20.93-7.63,3.66-15.68,3.69-23.42.39C4.69,75.84.56,68.5.23,59.34c-.4-10.95-.15-21.92-.12-32.89,0-3,2.08-4.96,4.92-4.92,2.87.04,4.84,2.02,4.85,5.01.03,9.82-.03,19.65.04,29.47.01,1.99.23,4.03.72,5.96,1.89,7.41,8.56,11.48,16.58,10.24,7.68-1.19,12.18-6.47,12.31-14.96.16-9.88.05-19.76.06-29.63,0-.54-.03-1.09.02-1.63.22-2.67,2.34-4.51,5.08-4.45,2.65.06,4.63,1.96,4.65,4.66.05,5.64.02,11.29.02,16.93Z" />
                        <path className="cls-1" d="M110.74,59.3c0,5.42,0,10.85,0,16.27,0,3.28-1.89,5.33-4.84,5.31-2.96-.01-4.91-2.08-4.92-5.29-.01-9.55.02-19.09-.03-28.64,0-1.83-.11-3.7-.48-5.5-1.51-7.44-7.11-11.72-14.79-11.44-9.14.33-14.36,6.16-14.41,16.17-.04,9.76,0,19.53-.02,29.29,0,2.78-1.27,4.62-3.51,5.22-3.14.84-5.98-1.12-6.28-4.37-.04-.43-.03-.87-.03-1.3,0-9.82,0-19.64,0-29.45,0-4.11.61-8.11,2.34-11.88,2.82-6.14,7.51-10.18,13.99-12.01,5.61-1.58,11.29-1.55,16.9.05,9.72,2.78,15.96,11.52,16.06,22.41.05,5.04,0,10.09,0,15.13Z" />
                        <path className="cls-1" d="M258.84,43.04c0,6.07.47,12.19-.1,18.21-1.17,12.33-10.64,20.4-23.1,20.5-5.32.04-10.45-.56-15.04-3.42-7.34-4.57-10.58-11.5-10.73-19.87-.18-10.63-.06-21.27-.04-31.91,0-3.03,1.95-5.01,4.8-5.01,2.94,0,4.93,2.01,4.94,5.09.02,9.71-.08,19.43.07,29.14.04,2.67.44,5.45,1.31,7.96,2.2,6.3,8.6,9.58,15.87,8.5,8-1.19,12.42-6.76,12.46-15.86.04-9.88,0-19.76.02-29.63,0-3.2,2.01-5.23,5-5.19,2.88.03,4.79,2.05,4.81,5.23.04,5.43,0,10.85,0,16.28-.09,0-.19,0-.28,0Z" />
                        <path className="cls-1" d="M271.24,59.7c0-5.1-.13-10.2.02-15.29.42-14.18,11.35-23.92,25.51-22.86,2.25.17,3.94,1.81,4.17,4.06.26,2.47-.91,4.39-3.16,5.02-.77.22-1.61.27-2.41.28-8,.06-13.2,4.66-14.1,12.61-.21,1.83-.26,3.68-.26,5.52-.03,8.9,0,17.79-.02,26.69,0,2.75-1.48,4.63-3.88,5.07-3.31.61-5.87-1.5-5.91-4.98-.05-5.37-.01-10.74-.01-16.11.01,0,.03,0,.04,0Z" />
                        <path className="cls-1" d="M122.87,51.15c0-8.14-.02-16.28,0-24.42,0-3.05,1.91-5.11,4.68-5.2,2.71-.09,4.69,1.57,5.08,4.26.06.43.09.86.09,1.3,0,16.06.01,32.13,0,48.19,0,3.58-2,5.73-5.11,5.61-2.88-.11-4.74-2.15-4.74-5.31-.02-8.14,0-16.28,0-24.42Z" />
                        <path className="cls-1" d="M122.23,6.41c0-4.37,2.83-7.03,6.67-6.29,3.14.61,4.85,3.45,4.47,7.41-.3,3.15-2.66,5.29-5.75,5.23-3.35-.06-5.39-2.47-5.39-6.36Z" />
                        <path className="cls-2" d="M188.03,46.24h-33.49c-.96-6.73,7.77-15.63,15.93-16.15,9.18-.58,16.61,6.05,17.56,16.15Z" fill="none" />
                        <path className="cls-2" d="M312.68,51.06c.13-10.22,6.28-18.41,15.45-20.43,11.39-2.5,22.3,5.18,23.97,17.01,1.14,8.12-.89,15.28-7.49,20.52-11.47,9.1-28.46,2.4-31.34-12.27-.31-1.59-.39-3.22-.58-4.83Z" fill="none" />
                    </svg>
                </Link>
            </div>
            <div className="">
                <HeaderDropdownMenu source={workspaceNavigationList} />
            </div>
            {/* <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
                {
                    props.links?.map((link: LinkProps, index: number) => {
                        const label = dictionary?.navigationMenu?.[link.name.toLowerCase()] ?? link.label;
                        return (
                            <Link
                                key={index}
                                href={link.href || "#"}
                                className={"text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap " + ((pathname == link.href) ? 'font-bold text-foreground' : '')}
                            >
                                {label}
                            </Link>
                        )
                    })
                }
            </nav> */}
            <div className="flex w-full h-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
                <div className="mx-auto flex-1 sm:flex-initial">
                    {
                        (router.asPath.split('/')[1] === 'catalogue' || router.asPath.split('/')[1] === 'search') &&
                        <form onSubmit={onSubmit}>
                            <div className="relative">
                                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="search"
                                    placeholder={/* dictionary?.mainHeader?.searchField?.placeholder */'Cerca nel catalogo...'}
                                    className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[400px] rounded-full"
                                />
                            </div>
                        </form>}
                </div>
                {
                    (router.asPath.split('/')[1] === 'catalogue' || router.asPath.split('/')[1] === 'search') &&
                    <DropdownMenu>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Link href={'/cart'} className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 w-10 rounded-full relative">
                                        <ShoppingCart className="h-5 w-5" />
                                        <span className="sr-only">{dictionary?.mainHeader?.userMenu?.title}</span>
                                        <span className='border px-2.25 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground hover:bg-primary/80 flex h-5 w-5 shrink-0 ml-1 items-center justify-center rounded-full absolute top-6 left-6'>0</span>
                                    </Link>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                Carrello
                            </TooltipContent>
                        </Tooltip>
                    </DropdownMenu>
                }


                {
                    (user?.userInfo?.role === 'ADMIN' || user?.userInfo?.role === 'SUPERVISOR') &&
                    <DropdownMenu>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Link
                                        href={'/backoffice'}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            (router.asPath.split('/')[1] === 'backoffice') ? router.push('/administration') : router.push('/backoffice');
                                        }}
                                        className={
                                            cn(
                                                'inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background',
                                                'transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
                                                'focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                                                'bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 w-10 rounded-full relative',
                                                ((router.asPath.split('/')[1]) === 'backoffice') ? 'text-primary-foreground bg-primary hover:bg-primary/80' : ''
                                            )
                                        }
                                    >
                                        <UserCogIcon className="w-5 h-5" />
                                    </Link>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                Backoffice
                            </TooltipContent>
                        </Tooltip>
                    </DropdownMenu>
                }

                {
                    ((user?.userInfo?.customers?.length > 0) && ((user?.userInfo?.role === 'ADMIN') || (user?.userInfo?.role === 'SUPERVISOR'))) &&
                    <DropdownMenu>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Link
                                        href={'/personification/?page=1&size=10&pageSize=10'}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            (router.asPath.split('/')[1] === 'personification') ? router.push('/administration') : router.push('/personification/?page=1&size=10&pageSize=10');
                                        }}
                                        className={
                                            cn(
                                                'inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background',
                                                'transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
                                                'focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                                                'bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 w-10 rounded-full relative',
                                                ((router.asPath.split('/')[1]) === 'personification') ? 'text-primary-foreground bg-primary hover:bg-primary/80' : ''
                                            )
                                        }
                                    >
                                        <UsersIcon className="w-5 h-5" />
                                    </Link>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                Personificazione
                            </TooltipContent>
                        </Tooltip>
                    </DropdownMenu>}

                {
                    (false) &&
                    <DropdownMenu onOpenChange={() => setCustomerList(getCustomerProfiles())}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="secondary" size="icon" className="rounded-full">
                                        <UsersIcon className="h-5 w-5" />
                                        <span className="sr-only">{dictionary?.mainHeader?.languagesMenu?.title}</span>
                                    </Button>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                personifica un cliente
                            </TooltipContent>
                        </Tooltip>
                        <DropdownMenuContent align="end">
                            <Command>
                                <CommandInput placeholder="Cerca tra i clienti..." />
                                <CommandList>
                                    <CommandEmpty>{dictionary?.mainHeader?.favouriteProfiles?.nodata ?? 'no data'}</CommandEmpty>
                                    <CommandGroup>
                                        {
                                            customerList?.map((customerProfile: any, index: number) => (
                                                <CommandItem
                                                    key={customerProfile.name + '-' + index}
                                                    value={dictionary?.table?.columns?.name?.options?.[customerProfile.name]?.label ?? customerProfile.name}
                                                    onSelect={(currentValue: any) => {
                                                        personificationChange(customerProfile, 'personification');
                                                    }}
                                                >
                                                    <CheckIcon
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            user?.userInfo?.id === customerProfile?.id ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {dictionary?.table?.columns?.name?.options?.[customerProfile.name]?.label ?? customerProfile.name}
                                                </CommandItem>
                                            ))
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </DropdownMenuContent>
                    </DropdownMenu>
                }

                {
                    ((user?.userInfo?.addressees?.length > 1)) &&
                    <DropdownMenu onOpenChange={() => setDestinationList(getDestinationList())}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="secondary" size="icon" className="rounded-full">
                                        <GoalIcon className="h-5 w-5" />
                                        <span className="sr-only">{dictionary?.mainHeader?.languagesMenu?.title}</span>
                                    </Button>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                Destinazioni
                            </TooltipContent>
                        </Tooltip>
                        <DropdownMenuContent align="end">
                            <Command>
                                <CommandInput placeholder="Cerca tra le destinazioni..." />
                                <CommandList>
                                    <CommandEmpty>{dictionary?.mainHeader?.favouriteProfiles?.nodata ?? 'no data'}</CommandEmpty>
                                    <CommandGroup>
                                        {
                                            destinationList?.map((destination: any, index: number) => (
                                                <CommandItem
                                                    key={destination.code + '-' + index}
                                                    value={destination.description}
                                                    onSelect={(currentValue: any) => {
                                                        setDestination(destination);
                                                        router.push('/catalogue');
                                                    }}
                                                >
                                                    <CheckIcon
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            user?.userInfo?.currentDestination?.id === destination?.id ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {dictionary?.table?.columns?.name?.options?.[destination.name]?.label ?? destination.description}
                                                </CommandItem>
                                            ))
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </DropdownMenuContent>
                    </DropdownMenu>
                }

                {/* {
                    (true) &&
                    <DropdownMenu onOpenChange={() => setFavouriteList(getPreferredProfiles())}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="secondary" size="icon" className="rounded-full">
                                        <UsersIcon className="h-5 w-5" />
                                        <span className="sr-only">{dictionary?.mainHeader?.languagesMenu?.title}</span>
                                    </Button>
                                </DropdownMenuTrigger>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="flex items-center gap-4">
                                {dictionary?.mainHeader?.favouriteProfiles?.tooltip}
                            </TooltipContent>
                        </Tooltip>
                        <DropdownMenuContent align="end">
                            <Command>
                                <CommandInput placeholder={dictionary?.mainHeader?.favouriteProfiles?.placeholder ?? 'Search...'} />
                                <CommandList>
                                    <CommandEmpty>{dictionary?.mainHeader?.favouriteProfiles?.nodata ?? 'no data'}</CommandEmpty>
                                    <CommandGroup>
                                        {
                                            favouriteList?.map((favouriteProfile: any, index: number) => (
                                                <CommandItem
                                                    key={favouriteProfile.name + '-' + index}
                                                    value={dictionary?.table?.columns?.name?.options?.[favouriteProfile.name]?.label ?? favouriteProfile.name}
                                                    onSelect={(currentValue: any) => {
                                                        personificationChange(favouriteProfile, 'personification');
                                                    }}
                                                >
                                                    <CheckIcon
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            user?.userInfo?.id === favouriteProfile?.id ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                    {dictionary?.table?.columns?.name?.options?.[favouriteProfile.name]?.label ?? favouriteProfile.name}
                                                </CommandItem>
                                            ))
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </DropdownMenuContent>
                    </DropdownMenu>
                } */}
                {/* <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="secondary" size="icon" className="rounded-full">
                            <Languages className="h-5 w-5" />
                            <span className="sr-only">{dictionary?.mainHeader?.languagesMenu?.title}</span>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>{dictionary?.mainHeader?.languagesMenu?.current} : {locale}</DropdownMenuLabel>
                        <DropdownMenuLabel>{dictionary?.mainHeader?.languagesMenu?.title}</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => { router.push({ pathname, query }, asPath, { locale: navigator.language ?? 'it-IT' }); setCookie('NEXT_LOCALE', navigator.language ?? 'it-IT'); }} key="0">
                            <label>
                                {dictionary?.mainHeader?.languagesMenu?.browser}
                            </label>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => { router.push({ pathname, query }, asPath, { locale: 'it-IT' }); setCookie('NEXT_LOCALE', 'it-IT'); }} key="1">
                            <label>
                                {dictionary?.mainHeader?.languagesMenu[`it-IT`]}
                            </label>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => { router.push({ pathname, query }, asPath, { locale: 'en-GB' }); setCookie('NEXT_LOCALE', 'en-GB'); }} key="2">
                            <label>
                                {dictionary?.mainHeader?.languagesMenu[`en-GB`]}
                            </label>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => { router.push({ pathname, query }, asPath, { locale: 'en-US' }); setCookie('NEXT_LOCALE', 'en-US'); }} key="3">
                            <label>
                                {dictionary?.mainHeader?.languagesMenu[`en-US`]}
                            </label>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
                <ModeToggle dictionary={dictionary}></ModeToggle> */}
                <DropdownMenu>

                    {/* <Button variant="secondary" size="icon" className="rounded-full">
                            <CircleUser className="h-5 w-5" />
                            <span className="sr-only">{dictionary?.mainHeader?.userMenu?.title}</span>
                        </Button> */}
                    <div className='h-full flex flex-col'>
                        {
                            /* user.active && */
                            ((user?.userInfo?.personificated) && (user?.userInfo?.role !== 'USER') && (user?.userInfo?.role !== 'LIMITED')) &&
                            <div
                                onClick={(event) => { event.preventDefault(); ejectPersonification(); }}
                                className='block bg-primary hover:bg-secondary text-primary-foreground hover:text-secondary-foreground font-bold leading-3 flex justify-between content-center cursor-pointer'
                                style={{ padding: '0.075rem 0.25rem', position: 'relative', zIndex: 1000 }}
                            >
                                <label className="inline-block cursor-pointer" style={{ fontSize: '0.45rem' }}>
                                    {dictionary?.mainHeader?.userMenu?.['active-personification'] ?? 'ACTIVE PERSONIFICATION!'}
                                </label>
                                <LogOut className='inline-block h-3 w-3 cursor-pointer' style={{ fontSize: '0.4rem' }}></LogOut>
                            </div>
                        }
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="ghost"
                                size="default"
                                className="group block p-1 rounded-md pr-3 md:pr-4 h-full"
                                style={{ outline: 'none', boxShadow: 'none' }}
                            >
                                <div className='block'>
                                    <CircleUser className="h-7 w-7 inline-block align-middle" />
                                    <div className='text-left inline-block align-middle ml-2 leading-4'>
                                        <div>
                                            <span className="block" style={{ fontSize: '0.8rem' }}>{dictionary?.table?.columns?.name?.options?.[user?.userInfo?.name]?.label ?? user?.userInfo?.name ?? ''}</span>
                                            {/* <Separator className='bg-foreground group-[:hover]:bg-secondary-foreground'></Separator>
                                            <span className="block text-xs" style={{ fontSize: '0.7rem' }}>{dictionary?.table?.columns?.role?.options?.[user?.userInfo?.role]?.label ?? user?.userInfo?.role ?? ''}</span> */}
                                            <Separator className='bg-foreground group-[:hover]:bg-accent-foreground'></Separator>
                                            <span className="block text-[0.7rem] leading-none">{user?.userInfo?.customer ?? ''}</span>


                                            <span className="block !text-[0.5725rem] leading-none">{user?.userInfo?.currentDestination?.description ?? ''}</span>

                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </DropdownMenuTrigger>
                    </div>

                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>{dictionary?.mainHeader?.userMenu?.system}</DropdownMenuLabel>

                        <DropdownMenuSeparator />

                        <DropdownMenuSub>
                            <DropdownMenuSubTrigger className='hover:text-accent-foreground'>{dictionary?.mainHeader?.themeMenu.title ?? 'Toggle theme'}</DropdownMenuSubTrigger>
                            <DropdownMenuSubContent>
                                <DropdownMenuRadioGroup value={theme} onValueChange={setTheme}>
                                    {
                                        themes.map((currentTheme, index) => {
                                            const label = dictionary?.mainHeader?.themeMenu[currentTheme.toLowerCase()] ?? currentTheme;
                                            return (
                                                <DropdownMenuRadioItem className={'DropDownMenuRadioItem'} key={index} value={currentTheme}>
                                                    <DropdownMenuItemIndicator className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
                                                        <CheckIcon className='inline-block w-4 h-4 align-text-top'></CheckIcon>
                                                    </DropdownMenuItemIndicator>
                                                    {label.charAt(0).toUpperCase() + label.slice(1)}
                                                </DropdownMenuRadioItem>
                                            )
                                        })
                                    }
                                </DropdownMenuRadioGroup>
                            </DropdownMenuSubContent>
                        </DropdownMenuSub>

                        <DropdownMenuSub>
                            <DropdownMenuSubTrigger className='hover:text-accent-foreground'>{dictionary?.mainHeader?.languagesMenu?.title}</DropdownMenuSubTrigger>
                            <DropdownMenuSubContent>
                                <DropdownMenuRadioGroup value={locale ?? getCookie('NEXT_LOCALE')} onValueChange={setLanguage}>
                                    <DropdownMenuRadioItem className={'DropDownMenuRadioItem'} key={0} value={(typeof window !== 'undefined') ? navigator.language : ''}>
                                        <DropdownMenuItemIndicator className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
                                            <CheckIcon className='inline-block w-4 h-4 align-text-top'></CheckIcon>
                                        </DropdownMenuItemIndicator>
                                        {dictionary?.mainHeader?.languagesMenu?.browser}
                                    </DropdownMenuRadioItem>
                                    <DropdownMenuRadioItem className={'DropDownMenuRadioItem'} key={1} value={'it-IT'}>
                                        <DropdownMenuItemIndicator className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
                                            <CheckIcon className='inline-block w-4 h-4 align-text-top'></CheckIcon>
                                        </DropdownMenuItemIndicator>
                                        {dictionary?.mainHeader?.languagesMenu[`it-IT`]}
                                    </DropdownMenuRadioItem>
                                    <DropdownMenuRadioItem className={'DropDownMenuRadioItem'} key={2} value={'en-GB'}>
                                        <DropdownMenuItemIndicator className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
                                            <CheckIcon className='inline-block w-4 h-4 align-text-top'></CheckIcon>
                                        </DropdownMenuItemIndicator>
                                        {dictionary?.mainHeader?.languagesMenu[`en-GB`]}
                                    </DropdownMenuRadioItem>
                                    <DropdownMenuRadioItem className={'DropDownMenuRadioItem'} key={3} value={'en-US'}>
                                        <DropdownMenuItemIndicator className='absolute left-2 flex h-3.5 w-3.5 items-center justify-center'>
                                            <CheckIcon className='inline-block w-4 h-4 align-text-top'></CheckIcon>
                                        </DropdownMenuItemIndicator>
                                        {dictionary?.mainHeader?.languagesMenu[`en-US`]}
                                    </DropdownMenuRadioItem>
                                </DropdownMenuRadioGroup>
                            </DropdownMenuSubContent>
                        </DropdownMenuSub>
                        <DropdownMenuSeparator />

                        <DropdownMenuLabel>{dictionary?.mainHeader?.userMenu?.title}</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                            <Link
                                href={'/personification?page=1&size=10'}
                                className='cursor-pointer'
                            >
                                Personificazione
                            </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>{dictionary?.mainHeader?.userMenu?.settings}</DropdownMenuItem>
                        <DropdownMenuItem>{dictionary?.mainHeader?.userMenu?.support}</DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => logout()}>
                            {dictionary?.mainHeader?.userMenu?.logout}
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header >
    )
}

export default MainHeader;
