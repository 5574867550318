import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { findCategoryNodeById } from '@/services/api/navigation.service';

const BreadcrumbNav = () => {
    const router = useRouter();

    const [pathParts, setPathParts]: any = React.useState([]);
    const [rawPathParts, setRawPathParts]: any = React.useState([]);

    // Split the path into segments for breadcrumb creation
    useEffect(() => {
        /* setRawPathParts(router.asPath.replace('/search/', '/search').replace('/catalogue/', '/catalogue').split('/').filter(Boolean)); */
        setRawPathParts(router.asPath.split('/').filter(Boolean));
        const tempPathParts = router.asPath.replace('?category=', '/').replace('&sub=', '/').replace(/\?page=\d+&pageSize=\d+?$/, '').replace(/\&page=\d+&pageSize=\d+?$/, '').replace(/\?searchText=[a-z0-9]+/, '').split('/').filter(Boolean);
        setPathParts(tempPathParts);
    }, [router.asPath]);

    /* return (
        <Breadcrumb separator=">">
            <BreadcrumbItem>
                <BreadcrumbLink asChild>
                    <Link href="/">Home</Link>
                </BreadcrumbLink>
            </BreadcrumbItem>
            {pathParts.map((part, index) => {
                const href = '/' + pathParts.slice(0, index + 1).join('/');

                // Capitalize the breadcrumb label (you can customize this as needed)
                const label = part.charAt(0).toUpperCase() + part.slice(1);

                return (
                    <BreadcrumbItem key={index}>
                        <BreadcrumbLink href={href}>
                            {label}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    ); */

    return (
        <>
            {
                (router.asPath !== '/') ?
                    <Breadcrumb className="px-4 py-2">
                        <BreadcrumbList>
                            {/* <BreadcrumbItem>
                    <BreadcrumbLink href="/">Home</BreadcrumbLink>
                </BreadcrumbItem> */}
                            {/* <BreadcrumbSeparator />
                <BreadcrumbItem>
                    <DropdownMenu>
                        <DropdownMenuTrigger className="flex items-center gap-1">
                            <BreadcrumbEllipsis className="h-4 w-4" />
                            <span className="sr-only">Toggle menu</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start">
                            <DropdownMenuItem>Documentation</DropdownMenuItem>
                            <DropdownMenuItem>Themes</DropdownMenuItem>
                            <DropdownMenuItem>GitHub</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </BreadcrumbItem> */}
                            {
                                pathParts.map((part: any, index: number) => {
                                    const rp = [...rawPathParts];
                                    const rawIndex = rp.indexOf(part);
                                    let href = '';

                                    if ((router.asPath.includes('search/?') || router.asPath.includes('catalogue/?'))) {
                                        if ((pathParts.length === (index + 1))) {
                                            href = router.asPath.replace('/search/', '/search').replace('/catalogue/', '/catalogue');
                                        } else {
                                            const lastIndex = router.asPath.lastIndexOf(part) + part.length;
                                            href = router.asPath.substring(lastIndex, -1);
                                        }
                                    } else {
                                        href = '/' + rawPathParts.slice(0, rawIndex + 1).join('/');
                                    }

                                    // Capitalize the breadcrumb label (you can customize this as needed)

                                    const rawLabel = findCategoryNodeById(JSON.parse(localStorage.getItem('CATALOGUE_TREE_DATA') ?? '[]'), part)?.name ?? part;
                                    const label = rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);

                                    return (
                                        <div className='flex flex-row items-center gap-1.5' key={index}>
                                            <BreadcrumbSeparator />
                                            <BreadcrumbItem>
                                                <BreadcrumbLink href={href}>
                                                    {label}
                                                </BreadcrumbLink>
                                            </BreadcrumbItem>
                                        </div>
                                    );
                                })
                            }
                            {/* <BreadcrumbItem>
                    <BreadcrumbLink href="/docs/components">Components</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                    <BreadcrumbPage>Breadcrumb</BreadcrumbPage>
                </BreadcrumbItem> */}
                        </BreadcrumbList>
                    </Breadcrumb>
                    :
                    <></>
            }
        </>)
};

export default BreadcrumbNav;
